import { basicPlan, proPlan, trialPlanType } from '../../constants';

/**
 * Checks if plan is helion pro trial
 * @param {object} plan subscription (plan)
 * @param {string} plan.type subscription type. Ex. "initial", "trial"
 * @param {object} plan.product subscription product (product)
 * @param {string} plan.product.type subscription product type. Ex. "helion_pro", "helion_basic", "helion_free"
 * @returns {boolean}
 */
export const isHelionProTrial = (plan) => {
  return plan?.type === trialPlanType && plan?.product?.type === proPlan;
};

/**
 * Checks if plan is helion basic trial
 * @param {object} plan subscription (plan)
 * @param {string} plan.type subscription type. Ex. "initial", "trial"
 * @param {object} plan.product subscription product (product)
 * @param {string} plan.product.type subscription product type. Ex. "helion_pro", "helion_basic", "helion_free"
 * @returns {boolean}
 */
export const isHelionBasicTrial = (plan) => {
  return plan?.type === trialPlanType && plan?.product?.type === basicPlan;
};
