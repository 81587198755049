import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Checkbox, FormHelperText } from '@mui/material';

import { ReactComponent as CheckboxIcon } from '../../assets/images/checkbox-icon.svg';
import { ReactComponent as CheckboxCheckedIcon } from '../../assets/images/checkbox-checked-icon.svg';

import './styles.scss';

/**
 * Renders checkbox for redux form
 * @memberof module:ReduxFormFields
 * @param  {Object} props - React component properties
 * @param  {Object} props.input
 * @param  {boolean} props.input.value
 * @param  {function} props.input.onChange
 * @param  {any} props.label - label text, element or node
 * @param  {string} props.customError - error message
 */
const CustomCheckbox = (props) => {
  const {
    input: { value, onChange },
    meta: { touched, error },
    label,
  } = props;

  return (
    <div className="custom-checkbox-container">
      <FormControlLabel
        control={
          <Checkbox
            checked={!!value}
            onChange={onChange}
            icon={<CheckboxIcon />}
            checkedIcon={<CheckboxCheckedIcon />}
            classes={{ root: 'disabled-checkbox-hover' }}
            disableRipple
            defaultChecked={false}
          />
        }
        label={label}
        classes={{ root: 'custom-checkbox-label' }}
      />
      <FormHelperText classes={{ root: 'validation-error' }}>
        {touched && error ? error : <>&nbsp;</>}
      </FormHelperText>
    </div>
  );
};

CustomCheckbox.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }).isRequired,
  label: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]).isRequired,
};

export default CustomCheckbox;
