import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useSnackbar } from 'notistack';

import { removeNotification } from './actions';

import { notificationSelector, notificationToRemoveSelector } from './selectors';

const Notifications = (props) => {
  const { notification, notificationToRemove, clearNotificationToRemoveKey } = props;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { message, options } = notification;

  useEffect(() => {
    if (message) {
      enqueueSnackbar(message, options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  useEffect(() => {
    if (notificationToRemove) {
      closeSnackbar(notificationToRemove);
      clearNotificationToRemoveKey(); // in order to prevent notification from stucking
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationToRemove]);
  return null;
};

Notifications.propTypes = {
  notification: PropTypes.instanceOf(Object).isRequired,
  notificationToRemove: PropTypes.string.isRequired,
  clearNotificationToRemoveKey: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  notification: notificationSelector,
  notificationToRemove: notificationToRemoveSelector,
});

const mapDispatchToProps = (dispatch) => ({
  clearNotificationToRemoveKey: () => dispatch(removeNotification(null)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
