import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { TransitionGroup } from 'react-transition-group';
import { Collapse, Skeleton } from '@mui/material';

/**
 * Components
 */
import CustomButton from '../CustomButton';
import ChangePlan from '../ChangePlan';

/**
 * Selectors
 */
import {
  changePlanWindowSelector,
  currentPlanSelector,
  currentPlanTypeSelector,
  getPlansList,
  loadingSelector,
} from './selectors';
import { selectedPlanSelector } from '../ChangePlan/selectors';
import { loadingSelector as globalLoadingSelector } from '../LoadingSpinner/selectors';
import { isSubscriptionChangeAllowedSelector } from '../AuthorizationApp/selectors';
import { getUserCurrency, iframeModeSelector } from '../HomePage/selectors';

/**
 * Action creators
 */
import { toggleChangePlanWindow, changePlanRequest } from './actions';
import { changeIframeMode } from '../HomePage/actions';

/**
 * Internationalization
 */
import i18n from '../../i18n';

/**
 * Constants
 */
import { trialPlanType, voucherPlan } from '../../constants';

/**
 * Styles
 */
import './index.scss';

/**
 * Utils
 */
import { generateFormattedPrice } from '../ChangePlan/utils';

const MyPlan = (props) => {
  const {
    changePlanWindowStatus,
    currentPlan,
    toggleWindow,
    changePlan,
    loading,
    globalLoading,
    myPlanType,
    plansList,
    isSubscriptionChangeAllowed,
    userCurrency,
    iframeMode,
    closeForm,
    selectedPlan,
  } = props;

  const {
    label: currentPlanValue,
    className: currentPlanClassName,
    priceNumberValue,
    priceStringValue,
    subDescription = null,
    nextPaymentDate,
    currentProductVersion,
    planType: currentPlanType,
    billingCycle,
    endDate: currentPlanEndDate,
  } = currentPlan;

  // Show skeleton until page the page completed the first loading
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (loading) {
      setIsLoaded(true);
    }
  }, [loading]);

  if (loading || !isLoaded) {
    return (
      <div className="my-plan">
        <Skeleton
          variant="rectangular"
          width="100%"
          height={24}
        />
        <div className="next-payment-container">
          <Skeleton
            variant="rectangular"
            width="100%"
            height={21}
          />
        </div>
        <div className="amount-per-year-container">
          <Skeleton
            variant="rectangular"
            width="100%"
            height={21}
          />
        </div>
        <div className="change-plan-info skeleton">
          <Skeleton
            variant="rectangular"
            width="100%"
            height={30}
          />
        </div>
      </div>
    );
  }

  const currentOptionFromPlanList = plansList?.find(
    ({ product, type }) =>
      product?._id === currentProductVersion?.product?._id && type !== voucherPlan,
  );
  const currentFee = currentOptionFromPlanList?.fee?.find(
    (option) => option?.currency === userCurrency,
  );
  const valueOfLabel =
    currentFee && currentPlanType === voucherPlan
      ? generateFormattedPrice(currentFee.amount, currentFee.currency)
      : priceStringValue;

  const handlerSubmit = (values) => {
    const initialValues = { productVersionId: values?.plans };

    if (selectedPlan.planType === voucherPlan) {
      initialValues.endDate = values?.endDate;
    }

    if (iframeMode) {
      closeForm();
    }

    changePlan(initialValues);
  };

  return (
    <div className="my-plan">
      <div className="header">
        <span className="label">{i18n.t('myPlan')}</span>
        <div className={`plan-type ${currentPlanClassName}`}>
          <span>{currentPlanValue}</span>
        </div>
      </div>

      <TransitionGroup>
        {nextPaymentDate && (
          <Collapse
            mountOnEnter
            unmountOnExit
          >
            <div className="next-payment-container">
              <span className="next-payment-label">{i18n.t('nextPaymentDate')}</span>
              <span className="next-payment-value">{nextPaymentDate}</span>
            </div>
          </Collapse>
        )}

        {((priceNumberValue === 0 && billingCycle) || (priceNumberValue && billingCycle)) && (
          <Collapse
            mountOnEnter
            unmountOnExit
          >
            <div className="amount-per-year-container">
              {billingCycle === 'YEAR' ? (
                <span className="amount-per-year-label">{`${i18n.t('amountPerYear')}:`}</span>
              ) : (
                <span className="amount-per-year-label">{`${i18n.t('amountPerMonth')}:`}</span>
              )}
              <span className="amount-per-year-value">{valueOfLabel}</span>
            </div>
          </Collapse>
        )}

        {subDescription && myPlanType === trialPlanType && (
          <Collapse
            mountOnEnter
            unmountOnExit
          >
            <div className={`change-plan-info ${currentPlanClassName}`}>
              <span>{subDescription}</span>
            </div>
          </Collapse>
        )}

        {changePlanWindowStatus && (
          <Collapse
            mountOnEnter
            unmountOnExit
          >
            <ChangePlan
              initialValues={{
                plans: currentPlan.id,
                endDate: currentPlanEndDate,
              }}
              onSubmit={handlerSubmit}
            />
          </Collapse>
        )}

        {!changePlanWindowStatus && isSubscriptionChangeAllowed && (
          <Collapse
            mountOnEnter
            unmountOnExit
          >
            <CustomButton
              onClick={toggleWindow}
              disabled={globalLoading}
            >
              {i18n.t('changePlanButton').toUpperCase()}
            </CustomButton>
          </Collapse>
        )}
      </TransitionGroup>
    </div>
  );
};

MyPlan.propTypes = {
  changePlanWindowStatus: PropTypes.bool.isRequired,
  toggleWindow: PropTypes.func.isRequired,
  currentPlan: PropTypes.instanceOf(Object).isRequired,
  plansList: PropTypes.instanceOf(Array).isRequired,
  changePlan: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  userCurrency: PropTypes.string,
  endDate: PropTypes.string,
  globalLoading: PropTypes.bool.isRequired,
  myPlanType: PropTypes.string.isRequired,
  isSubscriptionChangeAllowed: PropTypes.bool.isRequired,
  iframeMode: PropTypes.number.isRequired,
  closeForm: PropTypes.func.isRequired,
  selectedPlan: PropTypes.instanceOf(Object).isRequired,
};

MyPlan.defaultProps = {
  userCurrency: undefined,
  endDate: undefined,
};

const mapStateToProps = createStructuredSelector({
  changePlanWindowStatus: changePlanWindowSelector,
  currentPlan: currentPlanSelector,
  loading: loadingSelector,
  globalLoading: globalLoadingSelector,
  userCurrency: getUserCurrency,
  myPlanType: currentPlanTypeSelector,
  plansList: getPlansList,
  isSubscriptionChangeAllowed: isSubscriptionChangeAllowedSelector,
  iframeMode: iframeModeSelector,
  selectedPlan: selectedPlanSelector,
});

const mapDispatchToProps = (dispatch) => ({
  toggleWindow: () => dispatch(toggleChangePlanWindow()),
  changePlan: (payload) => dispatch(changePlanRequest(payload)),
  closeForm: () => dispatch(changeIframeMode(0)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyPlan);
