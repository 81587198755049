import React from 'react';
import { ReactComponent as ErrorLogo } from '../../assets/images/PaymentProviderError.svg';

import i18n from '../../i18n';

import {
  ErrorFallbackContainer,
  ErrorLogoContainer,
  Title,
  SubTitle,
  ErrorDescription,
} from './styles';

const Dummy = () => (
  <ErrorFallbackContainer>
    <ErrorLogoContainer>
      <ErrorLogo />
    </ErrorLogoContainer>
    <Title>{i18n.t('oops')}</Title>
    <SubTitle>{i18n.t('somethingWentWrong')}</SubTitle>
    <ErrorDescription>
      <p>{i18n.t('paymentProviderProblem')}</p>
      <p>{i18n.t('pleaseTryLater')}</p>
    </ErrorDescription>
  </ErrorFallbackContainer>
);

export default Dummy;
