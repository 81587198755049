import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { v4 as uuidv4 } from 'uuid';

import CustomButton from '../../../CustomButton';
import CurrencySelect from '../../../CurrencySelect';

import {
  changeCardCredentialsFormLoadingStatus,
  changeIframeCurrencySelectStatus,
} from '../../actions';
import {
  changeIframeUserTokenLoadingStatus,
  fetchCurrentSubscriptionRequest,
} from '../../../MyPlan/actions';
import { changeIframeMode } from '../../../HomePage/actions';
import { addNotification } from '../../../Notifications/actions';
import { removePaymentErrorMessage } from '../../../PaymentError/actions';

import { javascriptUrlSelector } from '../../../MyPlan/selectors';
import {
  iframeCurrencySelectStatusSelector,
  possiblePaymentMethodsIdSelector,
} from '../../selectors';
import { loadingSelector as globalLoadingSelector } from '../../../LoadingSpinner/selectors';
import { paymentErrorSelector } from '../../../PaymentError/selectors';

import { containerId, scriptId } from './constants';

import i18n from '../../../../i18n';

import './styles.scss';

/**
 * in the case when the handler is not created yet we need to create handler
 * if the handler is created we don't need to create it again
 */
const createHandler = (() => {
  let handler = null;
  return (paymentMethodConfigurationId) => {
    if (!handler) handler = window.IframeCheckoutHandler(paymentMethodConfigurationId);
    return handler;
  };
})();

const CardCredentialsForm = (props) => {
  const {
    javascriptUrlData: { javascriptUrl, action },
    changeLoadingStatus,
    changeIframeUserTokenLoading,
    onSuccessChangePlan,
    closeForm,
    showCurrencySelect,
    changeCurrencySelectStatus,
    passiblePaymentMethodsId,
    globalLoading,
    showError,
    paymentError,
    hidePaymentError,
  } = props;

  const onSuccessHandlers = {
    addOrUpdateUserToken: closeForm,
    changeSubscriptionProductId: onSuccessChangePlan,
  };

  const onSuccessHandler = onSuccessHandlers[action];

  const paymentMethodConfigurationId = passiblePaymentMethodsId;
  const onLoad = () => {
    // Set here the id of the HTML element the payment iframe should be appended to.
    const handler = createHandler(paymentMethodConfigurationId);

    handler.setValidationCallback((validationResult) => {
      // You can reset payment errors

      if (validationResult.success) {
        // You can make ajax calls to backend

        // If success then...
        hidePaymentError();
        handler.submit(onSuccessHandler);
        changeCurrencySelectStatus(false);
      } else {
        // Not success

        // Display errors to customer
        validationResult.errors?.forEach((errorMessage) => {
          const key = uuidv4();
          showError({
            message: errorMessage,
            options: {
              key,
              autoHideDuration: 2600,
              variant: 'warning',
            },
          });
        });
      }
    });

    // Set the optional initialize callback
    handler.setInitializeCallback(() => {
      // Execute initialize code
      changeCurrencySelectStatus(true);
      changeLoadingStatus(false);
      changeIframeUserTokenLoading(false);

      if (paymentError?.message && Number.isSafeInteger(paymentError?.scrollToMessage?.delay)) {
        setTimeout(() => {
          window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth',
          });
        }, paymentError.scrollToMessage.delay);
      }
    });

    // Set the optional height change callback
    handler.setHeightChangeCallback((height) => {
      document
        .getElementById('walleeContainer')
        .getElementsByTagName('iframe')[0].style.height = `${height}px`;
    });

    handler.create(containerId);

    const saveCardBtn = document.getElementById('pay-button');
    saveCardBtn.onclick = handler.validate;
  };
  const onClean = () => {
    const iframe = document.querySelector(`#${containerId}`);
    if (iframe) {
      iframe.textContent = '';
    }
    const iframeScript = document.querySelector(`#${scriptId}`);
    if (iframeScript) {
      iframeScript.removeEventListener('load', onLoad);
      document.body.removeChild(iframeScript);
      iframeScript.remove();
    }
    changeCurrencySelectStatus(false);
  };

  useEffect(() => {
    onClean();
    if (javascriptUrl) {
      const script = document.createElement('script');

      script.id = scriptId;
      script.src = javascriptUrl;
      script.async = true;
      script.addEventListener('load', onLoad);

      document.body.appendChild(script);
    }

    return onClean;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [javascriptUrl]);

  return (
    <div className="card-credentials-form-container">
      <div
        className="wallee-container"
        id="walleeContainer"
      >
        <div className="wallee">
          <div className="iframe-integration">
            <div
              className="iframe-container"
              id={containerId}
            />
          </div>
        </div>
      </div>
      {showCurrencySelect ? (
        <div className="iframe-currency-selector">
          <CurrencySelect />
        </div>
      ) : null}
      <CustomButton
        id="pay-button"
        type="button"
        disabled={globalLoading}
      >
        {i18n.t('saveCardButton').toUpperCase()}
      </CustomButton>
    </div>
  );
};

CardCredentialsForm.propTypes = {
  javascriptUrlData: PropTypes.instanceOf(Object).isRequired,
  changeLoadingStatus: PropTypes.func.isRequired,
  changeIframeUserTokenLoading: PropTypes.func.isRequired,
  onSuccessChangePlan: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired,
  showCurrencySelect: PropTypes.bool.isRequired,
  changeCurrencySelectStatus: PropTypes.func.isRequired,
  passiblePaymentMethodsId: PropTypes.number.isRequired,
  globalLoading: PropTypes.bool.isRequired,
  showError: PropTypes.func.isRequired,
  paymentError: PropTypes.string,
  hidePaymentError: PropTypes.func.isRequired,
};

CardCredentialsForm.defaultProps = {
  paymentError: '',
};

const mapStateToProps = createStructuredSelector({
  javascriptUrlData: javascriptUrlSelector,
  showCurrencySelect: iframeCurrencySelectStatusSelector,
  passiblePaymentMethodsId: possiblePaymentMethodsIdSelector,
  globalLoading: globalLoadingSelector,
  paymentError: paymentErrorSelector,
});

const mapDispatchToProps = (dispatch) => ({
  changeLoadingStatus: (newStatus) => dispatch(changeCardCredentialsFormLoadingStatus(newStatus)),
  changeIframeUserTokenLoading: (newStatus) =>
    dispatch(changeIframeUserTokenLoadingStatus(newStatus)),
  onSuccessChangePlan: () => dispatch(fetchCurrentSubscriptionRequest()),
  closeForm: () => dispatch(changeIframeMode(0)),
  changeCurrencySelectStatus: (newStatus) => dispatch(changeIframeCurrencySelectStatus(newStatus)),
  showError: (props) => dispatch(addNotification(props)),
  hidePaymentError: () => dispatch(removePaymentErrorMessage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardCredentialsForm);
