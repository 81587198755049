export const CLOSE_CHANGE_PLAN_WINDOW = 'CLOSE_CHANGE_PLAN_WINDOW';
export const TOGGLE_CHANGE_PLAN_WINDOW = 'TOGGLE_CHANGE_PLAN_WINDOW';
export const FETCH_CURRENT_SUBSCRIPTION_REQUEST = 'FETCH_CURRENT_SUBSCRIPTION_REQUEST';
export const FETCH_CURRENT_SUBSCRIPTION_SUCCESS = 'FETCH_CURRENT_SUBSCRIPTION_SUCCESS';
export const FETCH_CURRENT_SUBSCRIPTION_FAILURE = 'FETCH_CURRENT_SUBSCRIPTION_FAILURE';
export const CHANGE_PLAN_REQUEST = 'CHANGE_PLAN_REQUEST';
export const CHANGE_PLAN_SUCCESS = 'CHANGE_PLAN_SUCCESS';
export const CHANGE_PLAN_FAILURE = 'CHANGE_PLAN_FAILURE';
export const FETCH_IFRAME_URL_TOKEN_REQUEST = 'FETCH_IFRAME_URL_TOKEN_REQUEST';
export const FETCH_IFRAME_URL_TOKEN_SUCCESS = 'FETCH_IFRAME_URL_TOKEN_SUCCESS';
export const FETCH_IFRAME_URL_TOKEN_FAILURE = 'FETCH_IFRAME_URL_TOKEN_FAILURE';
export const CHANGE_IFRAME_USER_TOKEN_LOADING_STATUS = 'CHANGE_IFRAME_USER_TOKEN_LOADING_STATUS';
export const FETCH_NEXT_PAYMENT_DATE_REQUEST = 'FETCH_NEXT_PAYMENT_DATE_REQUEST';
export const FETCH_NEXT_PAYMENT_DATE_SUCCESS = 'FETCH_NEXT_PAYMENT_DATE_SUCCESS';
export const FETCH_NEXT_PAYMENT_DATE_FAILURE = 'FETCH_NEXT_PAYMENT_DATE_FAILURE';
export const FETCH_END_DATE_REQUEST = 'FETCH_END_DATE_REQUEST';
export const FETCH_END_DATE_SUCCESS = 'FETCH_END_DATE_SUCCESS';
export const FETCH_END_DATE_FAILURE = 'FETCH_END_DATE_FAILURE';
