import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { TransitionGroup } from 'react-transition-group';
import { Collapse } from '@mui/material';
import moment from 'moment';

/**
 * Components
 */
import CustomButton from '../CustomButton';
import RadioButtons from '../RadioButtons';
import CustomCheckbox from '../CustomCheckbox';

/**
 * Action creators
 */
import { toggleChangePlanWindow } from '../MyPlan/actions';
import { changeIframeMode } from '../HomePage/actions';

/**
 * Selectors
 */
import { radioButtonsSelector, selectedPlanSelector } from './selectors';
import { currentPlanSelector } from '../MyPlan/selectors';
import { loadingSelector } from '../LoadingSpinner/selectors';
import { iframeModeSelector } from '../HomePage/selectors';

/**
 * Internationalization
 */
import i18n from '../../i18n';

/**
 * Constants
 */
import { freePlan, voucherPlan } from '../../constants';
import { appConfig } from '../../appConfig';

/**
 * Validators
 */
import { termsAndConditions } from '../../utils/validation';

/**
 * Styles
 */
import './styles.scss';

const ChangePlan = (props) => {
  const {
    currentPlan,
    selectedPlan,
    toggleChangePlane,
    globalLoading,
    closeForm,
    iframeMode,
    radioButtons,
    pristine,
    handleSubmit,
    endDate,
  } = props;

  const { currentProductVersion, id: currentPlanId, planType: currentPlanType } = currentPlan;

  const {
    productVersionId: selectedPlanId,
    pricePer,
    label: selectedPlanName,
    className,
    planType: selectedPlanType,
    billingCycle: selectedPlanBillingCycle,
    productType: selectedProductType,
  } = selectedPlan;

  const [changeButtonStatus, setChangeButtonStatus] = useState(true);

  useEffect(() => {
    if (selectedPlanId) {
      // True if selected plan is not the same as current plan, or if the current plan is voucher and end date is changed
      if (
        selectedPlanId !== currentPlanId ||
        (currentPlanType === voucherPlan && !moment(currentPlan?.endDate).isSame(endDate)) ||
        currentPlanType !== selectedPlanType
      ) {
        setChangeButtonStatus(false);
      } else {
        setChangeButtonStatus(true);
      }
    }
    return () => {
      setChangeButtonStatus(true);
    };
  }, [
    selectedPlanId,
    currentPlanId,
    currentPlanType,
    endDate,
    currentPlan?.endDate,
    selectedPlanType,
  ]);

  const onCancelClick = () => {
    toggleChangePlane();
    if (iframeMode) {
      closeForm();
    }
  };

  const changeButtonDisabled = changeButtonStatus || globalLoading;

  let amountPerYearLabel = '';
  if (selectedPlanType === voucherPlan) {
    amountPerYearLabel = i18n.t('amountVoucher');
  } else if (selectedProductType !== freePlan) {
    amountPerYearLabel = `${i18n.t('amountPer')} ${i18n
      .t(selectedPlanBillingCycle)
      .toLocaleLowerCase()}:`;
  } else {
    amountPerYearLabel = i18n.t('amount');
  }

  return (
    <div className="change-plan-container">
      <div className="radio-buttons-container">
        <form
          className="change-plan-form"
          name="changePlanForm"
          id="changePlanForm"
        >
          <Field
            name="plans"
            component={RadioButtons}
            props={{
              currentProductVersion,
              variants: radioButtons,
            }}
          />
        </form>
      </div>
      <div className="selected-plan-info-container">
        <div className="selected-plan-container">
          <span className="selected-plan-label">{i18n.t('selectedPlan')}</span>
          <div className={`selected-plan-value-container ${className}`}>
            <span className="selected-plan-value">{selectedPlanName}</span>
          </div>
        </div>

        <div className="amount-per-year-container">
          <span className="amount-per-year-label">{amountPerYearLabel}</span>
          <span className="amount-per-year-value">{pricePer}</span>
        </div>

        <TransitionGroup>
          {!changeButtonDisabled && (
            <Collapse>
              <div className="terms-and-conditions-container">
                <Field
                  name="termsAndConditions"
                  component={CustomCheckbox}
                  label={
                    <span className="terms-and-conditions-label">
                      {i18n.t('iAcceptThe')}
                      &nbsp;
                      <a
                        href={appConfig.REACT_APP_AGB_URL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {i18n.t('termsAndConditions')}
                      </a>
                    </span>
                  }
                  validate={[termsAndConditions]}
                />
              </div>
            </Collapse>
          )}
        </TransitionGroup>

        <div className="change-plan-buttons-container">
          <CustomButton
            onClick={onCancelClick}
            inverted
            disabled={globalLoading}
          >
            {i18n.t('cancelButton').toUpperCase()}
          </CustomButton>
          <CustomButton
            onClick={handleSubmit}
            disabled={changeButtonDisabled || pristine}
          >
            {i18n.t('changeButton').toUpperCase()}
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

const form = reduxForm({
  form: 'changePlanForm',
})(ChangePlan);

ChangePlan.propTypes = {
  radioButtons: PropTypes.instanceOf(Array).isRequired,
  currentPlan: PropTypes.instanceOf(Object).isRequired,
  selectedPlan: PropTypes.instanceOf(Object).isRequired,
  toggleChangePlane: PropTypes.func.isRequired,
  globalLoading: PropTypes.bool.isRequired,
  closeForm: PropTypes.func.isRequired,
  billingCycle: PropTypes.string.isRequired,
  iframeMode: PropTypes.number.isRequired,
  pristine: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  endDate: PropTypes.string,
};

ChangePlan.defaultProps = {
  endDate: undefined,
};

const formSelector = formValueSelector('changePlanForm');

const mapStateToProps = createStructuredSelector({
  radioButtons: radioButtonsSelector,
  currentPlan: currentPlanSelector,
  selectedPlan: selectedPlanSelector,
  globalLoading: loadingSelector,
  iframeMode: iframeModeSelector,
  endDate: (state) => formSelector(state, 'endDate'),
});

const mapDispatchToProps = (dispatch) => ({
  toggleChangePlane: () => dispatch(toggleChangePlanWindow()),
  closeForm: () => dispatch(changeIframeMode(0)),
});

export default connect(mapStateToProps, mapDispatchToProps)(form);
