import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { english, french, italian, german, danish } from '../translations';

i18n.use(initReactI18next).init({
  debug: process.env.NODE_ENV !== 'production',
  fallbackLng: 'English',
  resources: {
    English: {
      translation: english,
    },
    French: {
      translation: french,
    },
    Deutsch: {
      translation: german,
    },
    Italian: {
      translation: italian,
    },
    Danish: {
      translation: danish,
    },
  },
});

export default i18n;
