import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';

import LoadingSpinner from './components/LoadingSpinner';

import routes from './routes';

const App = () => {
  useEffect(() => {
    function isTextInput(node) {
      return ['INPUT', 'TEXTAREA'].indexOf(node.nodeName) !== -1;
    }

    document.addEventListener(
      'touchstart',
      (e) => {
        if (!isTextInput(e.target) && isTextInput(document.activeElement)) {
          document.activeElement.blur();
        }
      },
      false,
    );
  }, []);

  return (
    <LoadingSpinner>
      <Switch>
        {routes.map((route) => (
          <Route
            key={route.key}
            {...route}
          />
        ))}
      </Switch>
    </LoadingSpinner>
  );
};

export default App;
