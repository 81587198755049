import {
  FETCH_CURRENCIES_REQUEST,
  FETCH_CURRENCIES_SUCCESS,
  FETCH_CURRENCIES_FAILURE,
  FETCH_CARD_CREDENTIALS_FORM,
  CHANGE_CARD_CREDENTIALS_FORM_LOADING_STATUS,
  CHANGE_CURRENCY,
  SET_TRANSACTION_ID,
  FETCH_CURRENT_TOKEN_VERSION_REQUEST,
  FETCH_CURRENT_TOKEN_VERSION_SUCCESS,
  FETCH_CURRENT_TOKEN_VERSION_FAILURE,
  CHANGE_IFRAME_CURRENCY_SELECT_STATUS,
  FETCH_POSSIBLE_PAYMENT_METHODS_REQUEST,
  FETCH_POSSIBLE_PAYMENT_METHODS_SUCCESS,
  FETCH_POSSIBLE_PAYMENT_METHODS_FAILURE,
} from './types';

export const fetchCurrenciesRequest = () => ({
  type: FETCH_CURRENCIES_REQUEST,
});

export const fetchCurrenciesSuccess = (currencies) => ({
  type: FETCH_CURRENCIES_SUCCESS,
  payload: {
    currencies,
  },
});

export const fetchCurrenciesFailure = (errorMessage) => ({
  type: FETCH_CURRENCIES_FAILURE,
  payload: {
    errorMessage,
  },
});

export const fetchCardCredentialsForm = () => ({
  type: FETCH_CARD_CREDENTIALS_FORM,
});

export const changeCardCredentialsFormLoadingStatus = (newStatus) => ({
  type: CHANGE_CARD_CREDENTIALS_FORM_LOADING_STATUS,
  payload: {
    newStatus,
  },
});

export const updateCurrency = (newCurrency) => ({
  type: CHANGE_CURRENCY,
  payload: {
    newCurrency,
  },
});

export const setTransactionId = (transactionId) => ({
  type: SET_TRANSACTION_ID,
  payload: {
    transactionId,
  },
});

export const fetchCurrentTokenVersionRequest = () => ({
  type: FETCH_CURRENT_TOKEN_VERSION_REQUEST,
});

export const fetchCurrentTokenVersionSuccess = (data) => ({
  type: FETCH_CURRENT_TOKEN_VERSION_SUCCESS,
  payload: {
    data,
  },
});

export const fetchCurrentTokenVersionFailure = (errorMessage) => ({
  type: FETCH_CURRENT_TOKEN_VERSION_FAILURE,
  payload: {
    errorMessage,
  },
});

export const changeIframeCurrencySelectStatus = (newStatus) => ({
  type: CHANGE_IFRAME_CURRENCY_SELECT_STATUS,
  payload: {
    newStatus,
  },
});

export const fetchPossiblePaymentMethodsRequest = () => ({
  type: FETCH_POSSIBLE_PAYMENT_METHODS_REQUEST,
});

export const fetchPossiblePaymentMethodsSuccess = (data) => ({
  type: FETCH_POSSIBLE_PAYMENT_METHODS_SUCCESS,
  payload: {
    data,
  },
});

export const fetchPossiblePaymentMethodsFailure = (errorMessage) => ({
  type: FETCH_POSSIBLE_PAYMENT_METHODS_FAILURE,
  payload: {
    errorMessage,
  },
});
