import actionTypes from './types';

const INITIAL_STATE = {
  data: [],
  allLoading: {
    fetchPlansLoading: false,
  },
  errors: {
    fetchPlansError: null,
  },
  benefits: {
    plans: [],
    benefits: [],
  },
};

const plansReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PLANS_REQUEST:
      return {
        ...state,
        errors: {
          ...state.errors,
          fetchPlansError: null,
        },
        allLoading: {
          ...state.allLoading,
          fetchPlansLoading: true,
        },
      };
    case actionTypes.FETCH_PLANS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        errors: {
          ...state.errors,
          fetchPlansError: null,
        },
        allLoading: {
          ...state.allLoading,
          fetchPlansLoading: false,
        },
      };
    case actionTypes.FETCH_PLANS_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          fetchPlansError: action.payload,
        },
        allLoading: {
          ...state.allLoading,
          fetchPlansLoading: false,
        },
      };
    case actionTypes.FETCH_BENEFITS_SUCCESS:
      return {
        ...state,
        benefits: action.payload,
      };
    default:
      return state;
  }
};

export default plansReducer;
