import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { Backdrop, CircularProgress } from '@mui/material';

import { loadingSelector } from './selectors';

import './styles.scss';

const LoadingSpinner = ({ isLoading, children }) => (
  <>
    {isLoading ? (
      <Backdrop
        open
        className="custom-backdrop"
      >
        <CircularProgress className="circular-progress-container" />
      </Backdrop>
    ) : null}
    {children}
  </>
);

LoadingSpinner.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

const mapStateToProps = createStructuredSelector({
  isLoading: loadingSelector,
});

export default connect(mapStateToProps)(LoadingSpinner);
