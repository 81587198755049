import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';
import createSagaMiddleware from 'redux-saga';

import reducer from './rootReducer';
import saga from './rootSaga';

const initialState = {};

const sagaMiddleware = createSagaMiddleware();

const middleware = [sagaMiddleware];

let enhancer;

const nodeEnv = process.env.NODE_ENV;

if (nodeEnv !== 'production') {
  middleware.unshift(createLogger());
  const composeEnhancers = composeWithDevToolsDevelopmentOnly({
    shouldHotReload: false,
    trace: true,
  });
  enhancer = composeEnhancers(applyMiddleware(...middleware));
} else {
  enhancer = compose(applyMiddleware(...middleware));
}

const store = createStore(reducer, initialState, enhancer);

sagaMiddleware.run(saga);

export default store;
