import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';

import Dummy from './Dummy';

import { errorsSelector } from './selectors';

const ErrorFallback = ({ children, hasErrors }) => {
  if (hasErrors || !children) {
    return <Dummy />;
  }
  return children;
};

ErrorFallback.propTypes = {
  children: PropTypes.node.isRequired,
  hasErrors: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  hasErrors: errorsSelector,
});

export default connect(mapStateToProps)(ErrorFallback);
