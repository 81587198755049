import { createSelector } from 'reselect';

import { getAllLoading as getPlansLoadingSelector } from '../PlansTable/selectors';
import { getAllLoading as getMyPlanLoading } from '../MyPlan/selectors';
import { getAllLoading as getPaymentOptionLoading } from '../PaymentOption/selectors';
import { getAllLoading as getHomePageLoading } from '../HomePage/selectors';
import { getAllLoading as getAuthorizationLoading } from '../AuthorizationApp/selectors';

export const loadingSelector = createSelector(
  [getPlansLoadingSelector, getMyPlanLoading, getPaymentOptionLoading, getHomePageLoading],
  (isPlansLoading, isCurrentPlanLoading, isPaymentOptionLoading, isHomePageLoading) =>
    [
      ...Object.values(isPlansLoading),
      ...Object.values(isCurrentPlanLoading),
      ...Object.values(isPaymentOptionLoading),
      ...Object.values(isHomePageLoading),
      ...Object.values(getAuthorizationLoading),
    ].some((loading) => loading),
);
