import capitalize from 'lodash/capitalize';

import i18n from '../../i18n';

import { currencySymbols, voucherPlan, trialPlanType } from '../../constants';

/**
 * Returns formatted price string
 * @param {number} price - product price
 * @param {string} currency - currency type
 * @returns {string} - formatted price
 */
export const generateFormattedPrice = (price, currency) =>
  price
    ? `${Number.parseFloat(price).toFixed(2)} ${currencySymbols[currency]}`
    : capitalize(i18n.t('FREE'));

/**
 * Returns formatted label for select
 * @param {string|null} billingCycle - product billing cycle type
 * @param {string} price - formatted price value with currency symbol (100.50 CHF or 33.12 $)
 * @returns {string} - formatted label
 */
export const generateLabel = (billingCycle, amount, currency) => {
  const formattedPrice = generateFormattedPrice(amount, currency);
  return billingCycle && amount
    ? `${i18n.t(billingCycle.toLowerCase())}: ${formattedPrice}`
    : formattedPrice;
};

/**
 * Returns result of comparison of two values
 * @param {string} firstEl - The first element for comparison.
 * @param {string} secondEl - The second element for comparison.
 * @returns {number} - 0, 1 or -1
 */
export const compareByYear = (firstEl, secondEl) => {
  if (firstEl === 'YEAR') {
    return 1;
  }
  if (secondEl === 'YEAR') {
    return -1;
  }
  return 0;
};

/**
 * Get the plans that are allowed to be changed to
 * @param {array} plansList
 * @returns {object} - contains vouchers subscription and trial plans
 */
export const getPlans = (plansList) => {
  const vouchers = plansList.filter(({ type }) => type === voucherPlan);
  const trial = plansList.find(({ type }) => type === trialPlanType);
  const subscriptions = plansList
    .filter(({ type }) => type !== voucherPlan)
    .sort((a, b) => compareByYear(a?.product?.billingCycle, b?.product?.billingCycle));

  return { vouchers, subscriptions, trial };
};
