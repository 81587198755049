import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Typography } from '@mui/material';

import i18n from '../../i18n';

import './styles.scss';

const CurrencySelect = ({ showLabel }) => {
  return (
    <div className="currency-selector-container">
      {showLabel && <Typography fontSize={14} fontFamily="Arial,sans-serif" color="#7c7c7c">{i18n.t('curencyLabel')}</Typography>}
      <Typography sx={{ pt: '3px' }} fontSize={14}>CHF</Typography>
    </div>
  );
};

CurrencySelect.propTypes = {
  showLabel: PropTypes.bool,
};

CurrencySelect.defaultProps = {
  showLabel: true,
};

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CurrencySelect);
