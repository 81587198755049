import { createSelector } from 'reselect';

import { defaultCurrency } from '../../constants';

export const getAllLoading = (state) => state?.user?.allLoading;
export const getIframeMode = (state) => state?.user?.iframeMode;
export const getOfflineStatus = (state) => state?.user?.offline;
export const getAllPaymentMethodBrands = (state) => state?.user?.allPaymentMethodBrands;
export const getUserCurrency = (state) => state?.user?.user?.preferredCurrency;
export const getUserLanguage = (state) => state?.user?.user?.language;
export const getUser = (state) => state?.user?.user;

export const offlineStatusSelector = createSelector([getOfflineStatus], (offline) => offline);

export const iframeModeSelector = createSelector([getIframeMode], (iframeMode) => iframeMode);

export const userCurrencySelector = createSelector(
  [getUserCurrency],
  (preferredCurrency) => preferredCurrency || defaultCurrency,
);

export const userLanguageSelector = createSelector([getUserLanguage], (language) => language);

export const userIdSelector = createSelector([getUser], (user) => user?._id || '');
