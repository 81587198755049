/* eslint-disable react/prop-types */
import React from 'react';
import i18n from '../../i18n';
import { ReactComponent as Checkmark } from '../../assets/images/plans-checkmark.svg';

import { currencySymbols, defaultCurrency } from '../../constants';

/**
 * Returns columns for plans table
 * @param {Object[]} plans - The array of subscription objects
 * @param {String} currentCurrency
 * @param {Object[]} benefits
 * @returns {Object[]} An array containing the properties of the column header.
 */
export const getColumns = (plans, currentCurrency = defaultCurrency, benefits = []) => {
  return [
    {
      id: 'description',
      accessor: 'description',
      className: 'description',
      Header: i18n.t('plans'),
      Footer: `${i18n.t('amountPerMonth')} (${currencySymbols[currentCurrency]}):`,
      Cell: ({ value, row }) => {
        const { hints = [] } = row.original;
        return (
          <p className="m-0">
            <span className="desc">{value}</span>
            {hints.map((_, id) => (
              <span
                key={id}
                className={`hint-${id}`}
              >
                *
              </span>
            ))}
          </p>
        );
      },
    },
    ...benefits.map((benefit) => {
      const { product, fee } = plans.find(({ product }) => product.type === benefit) || {};
      const { amount } = fee?.find((item) => item.currency === currentCurrency) || {};
      return {
        id: benefit,
        accessor: benefit,
        className: benefit,
        Header: product?.name || '',
        Footer: () => (amount === 0 ? i18n.t('FREE') : Number.parseFloat(amount).toFixed(2)),
        Cell: ({ value }) => {
          if (typeof value === 'boolean') {
            return value ? <Checkmark /> : null;
          }
          return value >= 1000 ? 99 : value;
        },
      };
    }),
  ];
};
