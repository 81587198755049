import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from '../../api';
import { createStructuredSelector } from 'reselect';

import {
  setEndUserId as setEndUserIdAction,
  setUserJwtToken,
  fetchMeRequest,
  setAppType,
  fetchTransactionErrorRequest,
} from './actions';
import { openThanksModal } from '../ThanksModal/actions';

import { jwtTokenSelector } from './selectors';

const AuthorizationApp = (Component) => {
  const ProtectedRoute = (props) => {
    const {
      history, // eslint-disable-line
      match: {
        params: { jwt = '', endUserId = '' },
      },
      location: { search },
      setJwtToken,
      setEndUserId,
      myJwtToken,
      fetchMe,
      setAppTypeFromSearchQuery,
      openThanks,
      fetchTransactionErrorRequest,
    } = props;

    useEffect(() => {
      if (search) {
        const queryParams = new URLSearchParams(search);

        const appType = queryParams.get('app');
        setAppTypeFromSearchQuery(appType);

        const result = queryParams.get('result');
        if (result) {
          queryParams.delete('result');

          if (result === 'success') {
            openThanks();
          }
        }

        history.replace({
          search: queryParams.toString(),
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    useEffect(() => {
      if (jwt) {
        const jwtTokenToSet = jwt.startsWith('Bearer ') ? jwt : `Bearer ${jwt}`;
        setJwtToken(jwtTokenToSet);
        axios.defaults.headers.common.Authorization = jwtTokenToSet;
        fetchMe(jwt);

        if (endUserId) {
          setEndUserId(endUserId);
        }
        const queryParams = new URLSearchParams(search);

        const errorTransactionId = queryParams.get('errorTransactionId');
        if (errorTransactionId) {
          queryParams.delete('errorTransactionId');
          fetchTransactionErrorRequest(errorTransactionId);
          history.replace({
            search: queryParams.toString(),
          });
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jwt]);

    return myJwtToken ? <Component /> : null;
  };

  ProtectedRoute.propTypes = {
    match: PropTypes.instanceOf(Object).isRequired,
    setJwtToken: PropTypes.func.isRequired,
    setEndUserId: PropTypes.func.isRequired,
    myJwtToken: PropTypes.string.isRequired,
    fetchMe: PropTypes.func.isRequired,
    history: PropTypes.instanceOf(Object).isRequired,
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
    setAppTypeFromSearchQuery: PropTypes.func.isRequired,
    openThanks: PropTypes.func.isRequired,
    fetchTransactionErrorRequest: PropTypes.func.isRequired,
  };

  const mapStateToProps = createStructuredSelector({
    myJwtToken: jwtTokenSelector,
  });

  const mapDispatchToProps = (dispatch) => ({
    setJwtToken: (jwt) => dispatch(setUserJwtToken(jwt)),
    setEndUserId: (endUserId) => dispatch(setEndUserIdAction(endUserId)),
    fetchMe: () => dispatch(fetchMeRequest()),
    setAppTypeFromSearchQuery: (appType) => dispatch(setAppType(appType)),
    openThanks: () => dispatch(openThanksModal()),
    fetchTransactionErrorRequest: (transactionId) =>
      dispatch(fetchTransactionErrorRequest(transactionId)),
  });

  return connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
};

export default AuthorizationApp;
