import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { TransitionGroup } from 'react-transition-group';
import { Collapse } from '@mui/material';
import { useLocation } from 'react-router-dom';

/**
 * Components
 */
import PaymentOptionInfo from './components/PaymentOptionInfo';
import CustomButton from '../CustomButton';
import CardCredentialsForm from './components/CardCredentialsForm';
import { ReactComponent as PenIcon } from '../../assets/images/pen.svg';

/**
 * Selectors
 */
import { showEditSelector } from './selectors';
import { loadingSelector as globalLoadingSelector } from '../LoadingSpinner/selectors';
import { myRoleTypeSelector } from '../AuthorizationApp/selectors';
import { iframeModeSelector, userIdSelector } from '../HomePage/selectors';

/**
 * Actions
 */
import { fetchCurrenciesRequest, fetchCurrentTokenVersionRequest } from './actions';
import { fetchIframeUrlTokenRequest } from '../MyPlan/actions';
import { changeIframeMode } from '../HomePage/actions';
import { removePaymentErrorMessage } from '../PaymentError/actions';

/**
 * Internationalization
 */
import i18n from '../../i18n';

/**
 * Constants
 */
import {
  accessRole,
  PV_INSTALLER,
  PV_INSTALLER_EMPLOYEE,
  PV_INSTALLER_EMPLOYEE_READ_ONLY,
  ROOT,
  SOLAR_ADMIN,
  SUPPORT,
  END_USER,
} from '../../constants';

/**
 * Styles
 */
import './styles.scss';

const PaymentOption = (props) => {
  const {
    getCurrencies,
    showEdit,
    getIframeUrl,
    getCurrentTokenVersion,
    globalLoading,
    myRoleType,
    iframeMode,
    closeCardCredentialsForm,
    userId,
    hidePaymentError,
  } = props;

  const location = useLocation();
  const app = new URLSearchParams(location.search).get('app');
  const redirectUrl = new URLSearchParams(location.search).get('redirectUrl');

  useEffect(() => {
    if (location?.pathname?.includes('payment-credentials-form')) {
      getIframeUrl({ scrollToPaymentForm: true, app, redirectUrl });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isAccessGranted = [
      ROOT,
      SOLAR_ADMIN,
      SUPPORT,
      PV_INSTALLER,
      PV_INSTALLER_EMPLOYEE,
      PV_INSTALLER_EMPLOYEE_READ_ONLY,
      END_USER,
    ].includes(myRoleType);

    if (isAccessGranted && userId) {
      getCurrencies();
      getCurrentTokenVersion();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myRoleType, userId]);

  const cancelHandler = () => {
    hidePaymentError();
    closeCardCredentialsForm();
  };

  return (
    <div className="webview-component payment-option-container">
      <div className="header">
        <span className="label">{i18n.t('paymentOption')}</span>
        {showEdit && !accessRole.includes(myRoleType) ? (
          <div
            className="edit-container"
            onClick={() => getIframeUrl({ scrollToPaymentForm: true, app, redirectUrl })}
          >
            <PenIcon className="pen-icon" />
          </div>
        ) : null}
        {!showEdit && iframeMode === 1 ? (
          <span
            className="cancel-edit-button"
            onClick={cancelHandler}
          >
            {i18n.t('cancelButton').toUpperCase()}
          </span>
        ) : null}
      </div>

      <TransitionGroup className="sub-description-container sub-description-container-sm">
        {iframeMode === 1 && (
          <Collapse
            mountOnEnter
            unmountOnExit
          >
            <CardCredentialsForm />
          </Collapse>
        )}
      </TransitionGroup>

      <TransitionGroup className="sub-description-container sub-description-container-sm">
        {showEdit && (
          <Collapse
            mountOnEnter
            unmountOnExit
          >
            <PaymentOptionInfo />
          </Collapse>
        )}
      </TransitionGroup>

      <TransitionGroup className="sub-description-container sub-description-container-sm">
        {!showEdit && iframeMode === 0 && (
          <Collapse
            mountOnEnter
            unmountOnExit
          >
            <CustomButton
              onClick={() => getIframeUrl({ scrollToPaymentForm: true, app, redirectUrl })}
              disabled={globalLoading || accessRole.includes(myRoleType)}
            >
              {i18n.t('addPaymentOptionButton').toUpperCase()}
            </CustomButton>
          </Collapse>
        )}
      </TransitionGroup>
    </div>
  );
};

PaymentOption.propTypes = {
  getCurrencies: PropTypes.func.isRequired,
  getIframeUrl: PropTypes.func.isRequired,
  closeCardCredentialsForm: PropTypes.func.isRequired,
  showEdit: PropTypes.bool.isRequired,
  globalLoading: PropTypes.bool.isRequired,
  getCurrentTokenVersion: PropTypes.func.isRequired,
  myRoleType: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  iframeMode: PropTypes.number.isRequired,
  hidePaymentError: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  showEdit: showEditSelector,
  globalLoading: globalLoadingSelector,
  myRoleType: myRoleTypeSelector,
  iframeMode: iframeModeSelector,
  userId: userIdSelector,
});

const mapDispatchToProps = (dispatch) => ({
  closeCardCredentialsForm: () => dispatch(changeIframeMode(0)),
  getCurrencies: () => dispatch(fetchCurrenciesRequest()),
  getIframeUrl: (payload) => dispatch(fetchIframeUrlTokenRequest(payload)),
  getCurrentTokenVersion: () => dispatch(fetchCurrentTokenVersionRequest()),
  hidePaymentError: () => dispatch(removePaymentErrorMessage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentOption);
