import React from 'react';
import PropTypes from 'prop-types';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';

/**
 * Styles
 */
import './styles.scss';

const DatePicker = (props) => {
  const {
    input: { value, onChange },
    meta: { touched, invalid, error },
  } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        inputFormat="dd/MM/yy"
        mask="__/__/__"
        disablePast
        disableOpenPicker
        defaultProps={new Date()}
        value={value}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            error={touched && invalid}
            helperText={touched && error}
            classes={{
              root: 'date-field',
            }}
            FormHelperTextProps={{
              classes: {
                error: 'date-error',
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

DatePicker.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.oneOf([
      PropTypes.undefined,
      PropTypes.number,
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]).isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.oneOf([PropTypes.string, PropTypes.undefined]),
    invalid: PropTypes.bool.isRequired,
  }).isRequired,
};

export default DatePicker;
