import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from './types';

const INITIAL_STATE = {
  notificationToRemove: '',
  notification: {
    message: '',
    options: {},
  },
};

const notificationsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return {
        ...state,
        notification: {
          message: action.payload.message,
          options: action.payload.options,
        },
      };
    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notificationToRemove: action.payload.key,
      };
    default:
      return state;
  }
};

export default notificationsReducer;
