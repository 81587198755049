import styled, { css } from 'styled-components/macro';

export const invertedState = css`
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(0, 90, 155);

  span {
    color: rgb(0, 90, 155);
  }
`;

export const disabledState = css`
  background-color: rgba(0, 90, 155, 0.4);
  border: none;

  span {
    color: rgb(255, 255, 255);
  }
`;

export const disabledBareVariant = css`
  span {
    color: rgba(0, 90, 155, 0.4);
  }
`;

export const isActive = css`
  ${({ active }) => (active ? 'background-color: rgb(0, 90, 155)' : '')}
`;

export const isInverted = css`
  ${({ inverted }) => (inverted ? invertedState : '')}
`;

export const isDisabled = css`
  ${({ disabled }) => (disabled ? disabledState : '')}
`;

export const isDisabledBare = css`
  ${({ disabled }) => (disabled ? disabledBareVariant : '')}
`;

export const StyledButton = styled.button`
  width: 100%;
  height: 45px;
  align-self: center;
  border: none;
  border-radius: 55px;
  background-color: #005a9b;
  padding: 0;

  span {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.25px;
  }

  ${isActive}
  ${isInverted}
  ${isDisabled}
`;

export const BareButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;

  span {
    color: #005a9b;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.25px;
  }

  ${isDisabledBare}
`;
