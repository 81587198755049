import actionTypes from './types';

export const fetchPlansRequest = (userId) => ({
  type: actionTypes.FETCH_PLANS_REQUEST,
  payload: {
    userId,
  },
});

export const fetchPlansSuccess = (plans) => ({
  type: actionTypes.FETCH_PLANS_SUCCESS,
  payload: plans,
});

export const fetchBenefitsSuccess = (benefits) => ({
  type: actionTypes.FETCH_BENEFITS_SUCCESS,
  payload: benefits,
});

export const fetchPlansFailure = (errorMessage) => ({
  type: actionTypes.FETCH_PLANS_FAILURE,
  payload: errorMessage,
});
