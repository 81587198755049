import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { SnackbarProvider } from 'notistack';
import { Slide } from '@mui/material';

import PaymentOption from '../PaymentOption';
import Plan from '../Plan';
import { Header } from '../Header';
import ThanksModal from '../ThanksModal';
import ConfirmTransaction from '../ConfirmTransaction';
import Notifications from '../Notifications';
import Footer from '../Footer';
import PaymentError from '../PaymentError';
import { ReactComponent as ExclamationTriangleIcon } from '../../assets/images/exclamation-triangle.svg';

import { fetchAllPaymentMethodBrandsRequest, setOfflineStatus } from './actions';
import { fetchCurrentSubscriptionRequest } from '../MyPlan/actions';
import { addNotification, removeNotification } from '../Notifications/actions';

import { offlineStatusSelector, userIdSelector } from './selectors';
import { myRoleTypeSelector } from '../AuthorizationApp/selectors';

import i18n from '../../i18n';

import './styles.scss';
import {
  END_USER,
  PV_INSTALLER,
  PV_INSTALLER_EMPLOYEE,
  PV_INSTALLER_EMPLOYEE_READ_ONLY,
  ROOT,
  SOLAR_ADMIN,
  SUPPORT,
} from '../../constants';
import SolarTariff from '../SolarTariff';

/**
 * @ignore key needed for adding and removing no internet connection notification
 */
const noInternetConnection = 'noInternetConnection';

const HomePage = (props) => {
  const {
    fetchAllPaymentMethods,
    fetchCurrentSubscription,
    userId,
    changeOfflineStatus,
    showNotification,
    hideNotification,
    myRoleType,
  } = props;

  useEffect(() => {
    const isAccessGranted = [
      ROOT,
      SOLAR_ADMIN,
      SUPPORT,
      PV_INSTALLER,
      PV_INSTALLER_EMPLOYEE,
      PV_INSTALLER_EMPLOYEE_READ_ONLY,
      END_USER,
    ].includes(myRoleType);

    if (isAccessGranted && userId) {
      fetchCurrentSubscription();
      fetchAllPaymentMethods();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, myRoleType]);

  const setOnline = () => {
    changeOfflineStatus(true);
    showNotification({
      message: i18n.t(noInternetConnection),
      options: {
        key: noInternetConnection,
        persist: true,
        variant: 'warning',
      },
    });
  };
  const setOffline = () => {
    changeOfflineStatus(false);
    hideNotification(noInternetConnection);
  };

  useEffect(() => {
    window.addEventListener('offline', setOnline);
    window.addEventListener('online', setOffline);

    return () => {
      window.removeEventListener('offline', setOnline);
      window.removeEventListener('online', setOffline);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header />
      <div className="home-page-container">
        <SnackbarProvider
          className="snackbar-provider-container"
          maxSnack={3}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          TransitionComponent={Slide}
          transitionDuration={{
            appear: 600,
            exit: 600,
          }}
          iconVariant={{
            success: '✅',
            error: '✖️',
            warning: <ExclamationTriangleIcon />,
            info: 'ℹ️',
          }}
          dense
          preventDuplicate
          classes={{
            containerRoot: 'snackbar-provider-container-root',
            root: 'snackbar-provider-root',
            variantSuccess: 'snackbar-success',
            variantError: 'snackbar-error',
            variantWarning: 'snackbar-warning',
            variantInfo: 'snackbar-info',
          }}
        >
          <Notifications />
        </SnackbarProvider>
        <ThanksModal />
        <ConfirmTransaction />
        <div className="payment-platform-container">
          <Plan />
          <SolarTariff myRoleType={myRoleType} />
          <PaymentOption />
          <PaymentError />
        </div>
        <Footer />
      </div>
    </>
  );
};

HomePage.propTypes = {
  fetchAllPaymentMethods: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  fetchCurrentSubscription: PropTypes.func.isRequired,
  changeOfflineStatus: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  hideNotification: PropTypes.func.isRequired,
  myRoleType: PropTypes.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  userId: userIdSelector,
  offline: offlineStatusSelector,
  myRoleType: myRoleTypeSelector,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllPaymentMethods: () => dispatch(fetchAllPaymentMethodBrandsRequest()),
  fetchCurrentSubscription: () => dispatch(fetchCurrentSubscriptionRequest()),
  changeOfflineStatus: (newStatus) => dispatch(setOfflineStatus(newStatus)),
  showNotification: (props) => dispatch(addNotification(props)),
  hideNotification: (key) => dispatch(removeNotification(key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
