import { createSelector } from 'reselect';

import { END_USER, ROOT, SOLAR_ADMIN, PV_INSTALLER, webApp } from '../../constants';

export const getAllLoading = (state) => state?.authorization?.allLoading;
export const getJwtToken = (state) => state.authorization.jwt;
export const getEndUserId = (state) => state.authorization?.endUserId;
export const getMe = (state) => state?.authorization?.me;
export const getMyRoleType = (state) => state?.authorization?.me?.role?.type;
export const getErrors = (state) => state?.authorization?.errors;
export const getAppType = (state) => state?.authorization?.app;
export const getIsSubscriptionProductChangeAllowed = (state) =>
  state?.authorization?.isSubscriptionProductChangeAllowed;

export const jwtTokenSelector = createSelector([getJwtToken], (jwt) => jwt);

export const endUserIdSelector = createSelector([getEndUserId], (endUserId) => endUserId);

export const myRoleTypeSelector = createSelector([getMe], (user) => user?.role?.type || '');

export const appTypeSelector = createSelector([getAppType], (appType) => appType);

export const isSubscriptionChangeAllowedSelector = createSelector(
  [getIsSubscriptionProductChangeAllowed, getMyRoleType, getAppType],
  /**
   * Check if user is allowed to change subscription
   * @param {boolean} isAllowed Is allowed to change subscription for current user
   * @param {string} myRoleType User role type
   * @param {string} appType Current app type
   * @returns {boolean}
   */
  (isAllowed, myRoleType, appType) =>
    isAllowed === true &&
    (([ROOT, SOLAR_ADMIN, PV_INSTALLER].includes(myRoleType) && appType === webApp) ||
      [END_USER].includes(myRoleType)),
);
