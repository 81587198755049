import { appConfig } from '../appConfig';

export const USER_ME_API = `${appConfig.REACT_APP_BACKEND_API}/user/me`;
export const USERS_SMU_API = `${appConfig.REACT_APP_BACKEND_API}/users/solar-managers-users`;

// by default, use PAYMENT_SERVICE_API as base url
export const SUBSCRIPTION_CURRENT_PRODUCT_VERSIONS_API = '/subscription-current-product-versions';
export const CURRENCIES_API = '/currencies';
export const SUBSCRIPTIONS_CURRENT_API = '/subscriptions/current';
export const SUBSCRIPTIONS_CURRENT_USER_API = '/subscriptions/current/user';
export const SUBSCRIPTIONS_MY_API = '/subscriptions/my';
export const SUBSCRIPTIONS_CREATE_API = '/subscriptions/create';
export const SUBSCRIPTIONS_USER_CREATE_API = '/subscriptions/user/create';
export const IFRAME_URL_USER_TOKEN_API = '/user-token';
export const USER_CURRENT_TOKEN_VERSION_API = '/token-versions/current';
export const MY_CURRENT_TOKEN_VERSION_API = '/token-versions/my/current';
export const POSSIBLE_PAYMENT_METHODS_API = '/payment-methods';
export const ALL_PAYMENT_METHOD_BRANDS_API = '/payment-method-brands';
export const UPDATE_USER_CURRENCY_API = '/user-currency';
export const MY_CURRENT_SUBSCRIPTION_NEXT_PAYMENT_DATE_API = '/subscriptions/my/next-payment-date';
export const USER_CURRENT_SUBSCRIPTION_NEXT_PAYMENT_DATE_API = '/subscriptions/next-payment-date';
export const MY_NEXT_SUBSCRIPTION_API = '/subscriptions/my/next';
export const USER_NEXT_SUBSCRIPTION_API = '/subscriptions/next';
export const IS_SUBSCRIPTION_PRODUCT_CHANGE_ALLOWED_API =
  '/subscriptions/is-subscription-product-change-allowed';
export const SUBSCRIPTION_BENEFITS_API = '/subscriptions-benefits';
export const TRANSACTION_ERROR_API = '/transactions/error-reason';
