import { all, call, takeLatest, put } from '@redux-saga/core/effects';
import axios from '../../api';
import { v4 as uuidv4 } from 'uuid';

import {
  ALL_PAYMENT_METHOD_BRANDS_API,
  UPDATE_USER_CURRENCY_API,
  USERS_SMU_API,
} from '../../api/endpoints';

import {
  FETCH_ALL_PAYMENT_METHOD_BRANDS_REQUEST,
  UPDATE_CURRENCY_REQUEST,
  FETCH_USER_REQUEST,
} from './types';

import {
  fetchUserFailure,
  fetchUserSuccess,
  fetchAllPaymentMethodBrandsFailure,
  fetchAllPaymentMethodBrandsSuccess,
  updateCurrencyFailure,
  updateCurrencySuccess,
} from './actions';
import { addNotification } from '../Notifications/actions';

import { fetchMe } from '../AuthorizationApp/sagas';

import { setLanguage } from '../../i18n/utils';
import i18n from '../../i18n';

export function* fetchUser(action) {
  try {
    const {
      payload: { userId },
    } = action;
    const { data } = yield call(() => axios.get(`${USERS_SMU_API}/${userId}`));

    const { language } = data || {};
    setLanguage(language);

    yield put(fetchUserSuccess(data));
  } catch (error) {
    yield put(fetchUserFailure(error?.message || i18n.t('fetchUserSagaError')));
  }
}

export function* fetchAllPaymentMethodBrands() {
  try {
    const { data } = yield call(() => axios.get(ALL_PAYMENT_METHOD_BRANDS_API));
    yield put(fetchAllPaymentMethodBrandsSuccess(data));
    yield put(fetchAllPaymentMethodBrandsSuccess(data));
  } catch (error) {
    yield put(fetchAllPaymentMethodBrandsFailure(error.message));
  }
}

export function* updateCurrency(action) {
  try {
    const {
      payload: { currency },
    } = action;
    const params = { preferredCurrency: currency };
    yield call(() => axios.put(UPDATE_USER_CURRENCY_API, params));
    yield put(updateCurrencySuccess());
    yield fetchMe();
  } catch (error) {
    yield put(updateCurrencyFailure(error.message));
    const key = uuidv4();
    yield put(
      addNotification({
        message: error?.response?.data?.message || i18n.t('error'),
        options: {
          key,
          autoHideDuration: 2600,
          variant: 'warning',
        },
      }),
    );
  }
}

export function* onFetchUserRequest() {
  yield takeLatest(FETCH_USER_REQUEST, fetchUser);
}

export function* onFetchAllPaymentMethodBrands() {
  yield takeLatest(FETCH_ALL_PAYMENT_METHOD_BRANDS_REQUEST, fetchAllPaymentMethodBrands);
}

export function* onUpdateCurrency() {
  yield takeLatest(UPDATE_CURRENCY_REQUEST, updateCurrency);
}

export default function* userSaga() {
  yield all([
    call(onFetchAllPaymentMethodBrands),
    call(onFetchUserRequest),
    call(onUpdateCurrency),
  ]);
}
