import {
  CLOSE_CHANGE_PLAN_WINDOW,
  TOGGLE_CHANGE_PLAN_WINDOW,
  FETCH_CURRENT_SUBSCRIPTION_REQUEST,
  FETCH_CURRENT_SUBSCRIPTION_SUCCESS,
  FETCH_CURRENT_SUBSCRIPTION_FAILURE,
  CHANGE_PLAN_REQUEST,
  CHANGE_PLAN_SUCCESS,
  CHANGE_PLAN_FAILURE,
  FETCH_IFRAME_URL_TOKEN_REQUEST,
  FETCH_IFRAME_URL_TOKEN_SUCCESS,
  FETCH_IFRAME_URL_TOKEN_FAILURE,
  CHANGE_IFRAME_USER_TOKEN_LOADING_STATUS,
  FETCH_END_DATE_FAILURE,
  FETCH_END_DATE_SUCCESS,
  FETCH_NEXT_PAYMENT_DATE_REQUEST,
  FETCH_NEXT_PAYMENT_DATE_SUCCESS,
  FETCH_NEXT_PAYMENT_DATE_FAILURE,
  FETCH_END_DATE_REQUEST,
} from './types';

export const closeChangePlanWindow = () => ({
  type: CLOSE_CHANGE_PLAN_WINDOW,
});

export const toggleChangePlanWindow = () => ({
  type: TOGGLE_CHANGE_PLAN_WINDOW,
});

export const fetchCurrentSubscriptionRequest = (endUserId) => ({
  type: FETCH_CURRENT_SUBSCRIPTION_REQUEST,
  payload: {
    endUserId,
  },
});

export const fetchCurrentSubscriptionSuccess = ({ product, fee, type, _id, endDate }) => ({
  type: FETCH_CURRENT_SUBSCRIPTION_SUCCESS,
  payload: {
    product,
    fee,
    type,
    _id,
    endDate,
  },
});

export const fetchCurrentSubscriptionFailure = (errorMessage) => ({
  type: FETCH_CURRENT_SUBSCRIPTION_FAILURE,
  payload: {
    errorMessage,
  },
});

export const changePlanRequest = ({ productVersionId, endDate }) => ({
  type: CHANGE_PLAN_REQUEST,
  payload: {
    productVersionId,
    endDate,
  },
});

export const changeEndDateRequest = (endDate) => ({
  type: FETCH_END_DATE_REQUEST,
  payload: {
    endDate,
  },
});

export const changeEndDateRequestFailure = (endDate) => ({
  type: FETCH_END_DATE_FAILURE,
  payload: {
    endDate,
  },
});

export const changeEndDateRequestSuccess = (endDate) => ({
  type: FETCH_END_DATE_SUCCESS,
  payload: {
    endDate,
  },
});

export const changePlanSuccess = (javascriptUrl) => ({
  type: CHANGE_PLAN_SUCCESS,
  payload: {
    javascriptUrl,
  },
});

export const changePlanFailure = (errorMessage) => ({
  type: CHANGE_PLAN_FAILURE,
  payload: {
    errorMessage,
  },
});

export const fetchIframeUrlTokenRequest = (payload) => ({
  type: FETCH_IFRAME_URL_TOKEN_REQUEST,
  payload,
});

export const fetchIframeUrlTokenSuccess = (javascriptUrl) => ({
  type: FETCH_IFRAME_URL_TOKEN_SUCCESS,
  payload: {
    javascriptUrl,
  },
});

export const fetchIframeUrlTokenFailure = (errorMessage) => ({
  type: FETCH_IFRAME_URL_TOKEN_FAILURE,
  payload: {
    errorMessage,
  },
});

export const changeIframeUserTokenLoadingStatus = (newStatus) => ({
  type: CHANGE_IFRAME_USER_TOKEN_LOADING_STATUS,
  payload: {
    newStatus,
  },
});

export const fetchNextPaymentDateRequest = () => ({
  type: FETCH_NEXT_PAYMENT_DATE_REQUEST,
});

export const fetchNextPaymentDateSuccess = (nextPaymentDate) => ({
  type: FETCH_NEXT_PAYMENT_DATE_SUCCESS,
  payload: {
    nextPaymentDate,
  },
});

export const fetchNextPaymentDateFailure = (errorMessage) => ({
  type: FETCH_NEXT_PAYMENT_DATE_FAILURE,
  payload: {
    errorMessage,
  },
});
