import styled from 'styled-components/macro';

export const ErrorFallbackContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ErrorLogoContainer = styled.div`
  width: min(85vh, 317px);
  margin-top: min(25.86vh, 210px);
  display: flex;
  justify-content: center;
  align-items: flex-start;

  svg {
    width: 100%;
  }
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 24px;
  color: #4a4a4a;
  margin: min(2.7vh, 22px) 0 0;
`;

export const SubTitle = styled.h2`
  font-size: 14px;
  color: #4a4a4a;
  margin: 4px 0 0;
`;

export const ErrorDescription = styled.div`
  width: max(78.6vw, 295px);
  position: absolute;
  bottom: 37px;

  p {
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: #7f7f7f;
    margin: 0;
    line-height: 18px;
  }
`;
