import React, { useMemo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { useTable } from 'react-table';

import PlansTableSkeleton from './PlansTableSkeleton';
import {
  PV_INSTALLER,
  PV_INSTALLER_EMPLOYEE,
  PV_INSTALLER_EMPLOYEE_READ_ONLY,
  ROOT,
  SOLAR_ADMIN,
  SUPPORT,
  END_USER,
} from '../../constants';

import { plansTableDataSelector, benefitsTableDataSelector } from './selectors';
import { currentPlanSelector, loadingSelector } from '../MyPlan/selectors';
import { myRoleTypeSelector } from '../AuthorizationApp/selectors';
import { userCurrencySelector, userIdSelector } from '../HomePage/selectors';

import { fetchPlansRequest } from './actions';

import { getColumns } from './utils';

import './styles.scss';

const Plans = ({
  getPlans,
  loading,
  plansData,
  userCurrency,
  myRoleType,
  userId,
  benefitsData,
}) => {
  // Show skeleton until page the page completed the first loading
  const [isLoaded, setIsLoaded] = useState(false);

  const columns = useMemo(
    () => getColumns(plansData, userCurrency, benefitsData?.plans),
    [plansData, userCurrency, benefitsData],
  );

  const tableInstance = useTable({ columns, data: benefitsData.benefits || [] });
  const { getTableProps, getTableBodyProps, headerGroups, footerGroups, rows, prepareRow } =
    tableInstance;

  useEffect(() => {
    if (loading) {
      setIsLoaded(true);
    }
  }, [loading]);

  useEffect(() => {
    const isAccessGranted = [
      ROOT,
      SOLAR_ADMIN,
      SUPPORT,
      PV_INSTALLER,
      PV_INSTALLER_EMPLOYEE,
      PV_INSTALLER_EMPLOYEE_READ_ONLY,
      END_USER,
    ].includes(myRoleType);

    if (isAccessGranted && userId) {
      getPlans(userId);
    }
  }, [userId, myRoleType]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading || !isLoaded) {
    return <PlansTableSkeleton />;
  }

  return (
    <>
      <hr />
      <div className="plans">
        <table
          className="plans-table"
          {...getTableProps()}
        >
          <thead className="thead">
            {headerGroups.map((headerGroup, idx) => (
              <tr
                key={`thead_row_${idx}`}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column, columnIdx) => (
                  <th
                    key={`cell_${idx}_${columnIdx}`}
                    className={column?.className || ''}
                    {...column.getHeaderProps()}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            className="tbody"
            {...getTableBodyProps()}
          >
            {rows.map((row, idx) => {
              prepareRow(row);
              return (
                <tr
                  key={`tbody_row_${idx}`}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell, cellIdx) => (
                    <td
                      key={`cell_${idx}_${cellIdx}`}
                      {...(cell.column?.className ? { className: cell.column.className } : {})}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
        <table className="tfoot">
          <tbody>
            {footerGroups.map((group, idx) => (
              <tr
                key={`tfoot_row_${idx}`}
                {...group.getFooterGroupProps()}
              >
                {group.headers.map((column, cellIdx) => (
                  <td
                    key={`cell_${idx}_${cellIdx}`}
                    {...(column?.className ? { className: column.className } : {})}
                    {...column.getFooterProps()}
                  >
                    {column.render('Footer')}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <div className="hints-wrapper">
          {benefitsData.benefitHints?.map((item, id) => (
            <p
              key={item}
              className="hints"
            >
              <span
                key={id}
                className={`hint-${id}`}
              >
                *
              </span>
              -<span>{item}</span>
            </p>
          ))}
        </div>
      </div>
    </>
  );
};

Plans.propTypes = {
  getPlans: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  plansData: PropTypes.instanceOf(Object).isRequired,
  userCurrency: PropTypes.string.isRequired,
  myRoleType: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  currentPlan: PropTypes.instanceOf(Object).isRequired,
  benefitsData: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = createStructuredSelector({
  loading: loadingSelector,
  plansData: plansTableDataSelector,
  userCurrency: userCurrencySelector,
  myRoleType: myRoleTypeSelector,
  userId: userIdSelector,
  currentPlan: currentPlanSelector,
  benefitsData: benefitsTableDataSelector,
});

const mapDispatchToProps = (dispatch) => ({
  getPlans: (userId) => dispatch(fetchPlansRequest(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Plans);
