import { useState, useEffect } from 'react';

/**
 * Custom react hook - if the [condition] has not changed in the last [ms] seconds, then return true, else return false
 * @param {boolean} condition
 * @param {number} ms
 * @returns {boolean}
 */
const useConditionalDelay = (condition, ms) => {
  const [allowed, setAllowed] = useState(false);
  useEffect(() => {
    let intervalId = null;
    if (condition) {
      const prevCondition = condition;
      intervalId = setInterval(() => {
        if (condition && prevCondition === condition) {
          setAllowed(true);
          if (intervalId) {
            clearInterval(intervalId);
          }
        }
      }, ms);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition]);
  return allowed;
};

export default useConditionalDelay;
