import { createSelector } from 'reselect';
import moment from 'moment';

import { defaultCurrency } from '../../constants';
import { generateFormattedPrice } from '../ChangePlan/utils';
import { getUserCurrency } from '../HomePage/selectors';

export const getChangePlanWindowStatus = (state) => state.myPlan.changePlanWindow.active;
export const getCurrentPlan = (state) => state.myPlan?.currentPlan;
export const getCurrentPlanEndDate = (state) => state.myPlan.currentPlan?.endDate;
export const getNewPlanId = (state) => state.myPlan.newPlanId;
export const getAllLoading = (state) => state.myPlan.allLoading;
export const getErrors = (state) => state.myPlan.errors;
export const getJavascriptUrl = (state) => state.myPlan.changePlanData;
export const getNextPaymentDate = (state) => state.myPlan.nextPaymentDate;
export const getPlanType = (state) => state.myPlan.currentPlan.type;
export const getProductIdOfCurrentPlan = (state) => state?.myPlan?.currentPlan?.product?._id;
export const getPlansList = (state) => state?.plansTable?.data;

export const endDateSelector = createSelector([getCurrentPlan], (endDate) => endDate);

export const javascriptUrlSelector = createSelector([getJavascriptUrl], (data) => data);

export const changePlanWindowSelector = createSelector(
  [getChangePlanWindowStatus],
  (active) => active,
);

export const currentPlanSelector = createSelector(
  [getCurrentPlan, getUserCurrency, getNextPaymentDate, getNewPlanId],
  (currentPlan, userCurrency, nextPaymentDate, newPlanId) => {
    const currentCurrency = userCurrency || defaultCurrency;

    const { _id: currentProductVersionId, product, fee, type: planType, endDate } = currentPlan;

    if (product) {
      const currencyObject = fee.find((item) => item.currency === currentCurrency);
      const { currency = defaultCurrency, amount = null } = currencyObject;
      const { name, subDescription, billingCycle, type: productType } = product;
      return {
        // TODO: avoid duplication, as we already have `planType`, `currentProductVersionId`
        currentProductVersion: currentPlan,
        currentProductVersionId,
        id: newPlanId,
        label: name.toUpperCase(),
        className: name.toLowerCase(),
        subDescription,
        priceNumberValue: amount,
        priceStringValue: generateFormattedPrice(amount, currency),
        nextPaymentDate: nextPaymentDate ? moment(nextPaymentDate).format('DD/MM/YYYY') : '',
        billingCycle,
        planType,
        productType,
        endDate,
      };
    }
    return {
      currentProductVersion: {},
      currentProductVersionId: '',
      id: '',
      label: '',
      className: '',
      subDescription: '',
      priceNumberValue: '',
      priceStringValue: '',
      nextPaymentDate: '',
      billingCycle: '',
      planType: '',
      productType: '',
    };
  },
);

export const loadingSelector = createSelector(
  [getAllLoading],
  ({ fetchCurrentSubscriptionRequestLoading }) => fetchCurrentSubscriptionRequestLoading,
);

export const currentPlanTypeSelector = createSelector([getPlanType], (newPlanType) => newPlanType);
