import {
  FETCH_ALL_PAYMENT_METHOD_BRANDS_REQUEST,
  FETCH_ALL_PAYMENT_METHOD_BRANDS_SUCCESS,
  FETCH_ALL_PAYMENT_METHOD_BRANDS_FAILURE,
  UPDATE_CURRENCY_REQUEST,
  UPDATE_CURRENCY_SUCCESS,
  UPDATE_CURRENCY_FAILURE,
  CHANGE_IFRAME_MODE,
  CHANGE_OFFLINE_STATUS,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
} from './types';

export const fetchAllPaymentMethodBrandsRequest = () => ({
  type: FETCH_ALL_PAYMENT_METHOD_BRANDS_REQUEST,
});

export const fetchAllPaymentMethodBrandsSuccess = (data) => ({
  type: FETCH_ALL_PAYMENT_METHOD_BRANDS_SUCCESS,
  payload: {
    data,
  },
});

export const fetchAllPaymentMethodBrandsFailure = (errorMessage) => ({
  type: FETCH_ALL_PAYMENT_METHOD_BRANDS_FAILURE,
  payload: {
    errorMessage,
  },
});

export const updateCurrencyRequest = (currency) => ({
  type: UPDATE_CURRENCY_REQUEST,
  payload: {
    currency,
  },
});

export const updateCurrencySuccess = () => ({
  type: UPDATE_CURRENCY_SUCCESS,
});

export const updateCurrencyFailure = (errorMessage) => ({
  type: UPDATE_CURRENCY_FAILURE,
  payload: {
    errorMessage,
  },
});

export const changeIframeMode = (mode) => ({
  type: CHANGE_IFRAME_MODE,
  payload: {
    mode,
  },
});

export const setOfflineStatus = (newStatus) => ({
  type: CHANGE_OFFLINE_STATUS,
  payload: {
    newStatus,
  },
});

export const fetchUserRequest = (userId) => ({
  type: FETCH_USER_REQUEST,
  payload: {
    userId,
  },
});

export const fetchUserSuccess = (data) => ({
  type: FETCH_USER_SUCCESS,
  payload: {
    data,
  },
});

export const fetchUserFailure = (errorMessage) => ({
  type: FETCH_USER_FAILURE,
  payload: {
    errorMessage,
  },
});
