import { createSelector } from 'reselect';

export const getNotification = (state) => state?.notifications?.notification;
export const getNotificationToRemove = (state) => state?.notifications?.notificationToRemove;

export const notificationSelector = createSelector(
  [getNotification],
  (notification) => notification,
);

export const notificationToRemoveSelector = createSelector([getNotificationToRemove], (key) => key);
