export const FETCH_ALL_PAYMENT_METHOD_BRANDS_REQUEST = 'FETCH_ALL_PAYMENT_METHOD_BRANDS_REQUEST';
export const FETCH_ALL_PAYMENT_METHOD_BRANDS_SUCCESS = 'FETCH_ALL_PAYMENT_METHOD_BRANDS_SUCCESS';
export const FETCH_ALL_PAYMENT_METHOD_BRANDS_FAILURE = 'FETCH_ALL_PAYMENT_METHOD_BRANDS_FAILURE';
export const UPDATE_CURRENCY_REQUEST = 'UPDATE_CURRENCY_REQUEST';
export const UPDATE_CURRENCY_SUCCESS = 'UPDATE_CURRENCY_SUCCESS';
export const UPDATE_CURRENCY_FAILURE = 'UPDATE_CURRENCY_FAILURE';
export const CHANGE_IFRAME_MODE = 'CHANGE_IFRAME_MODE';
export const CHANGE_OFFLINE_STATUS = 'CHANGE_OFFLINE_STATUS';
export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';
