import { all, call, takeLatest, put, select } from '@redux-saga/core/effects';
import axios from '../../api';
import { v4 as uuidv4 } from 'uuid';

import i18n from '../../i18n';

import {
  CURRENCIES_API,
  IFRAME_URL_USER_TOKEN_API,
  MY_CURRENT_TOKEN_VERSION_API,
  POSSIBLE_PAYMENT_METHODS_API,
  USER_CURRENT_TOKEN_VERSION_API,
} from '../../api/endpoints';

import {
  FETCH_CURRENCIES_REQUEST,
  FETCH_CURRENT_TOKEN_VERSION_REQUEST,
  FETCH_POSSIBLE_PAYMENT_METHODS_REQUEST,
} from './types';
import { FETCH_IFRAME_URL_TOKEN_REQUEST } from '../MyPlan/types';

import {
  fetchCurrenciesSuccess,
  fetchCurrenciesFailure,
  setTransactionId,
  fetchCurrentTokenVersionSuccess,
  fetchCurrentTokenVersionFailure,
  fetchPossiblePaymentMethodsSuccess,
  fetchPossiblePaymentMethodsFailure,
} from './actions';
import { fetchIframeUrlTokenSuccess, fetchIframeUrlTokenFailure } from '../MyPlan/actions';
import { changeIframeMode } from '../HomePage/actions';
import { addNotification } from '../Notifications/actions';

import { getEndUserId, getMyRoleType } from '../AuthorizationApp/selectors';

import { accessRole, END_USER } from '../../constants';

export function* fetchCurrencies() {
  try {
    const { data } = yield call(() => axios.get(CURRENCIES_API));
    yield put(fetchCurrenciesSuccess(data));
  } catch (error) {
    yield put(fetchCurrenciesFailure(error.message));
  }
}

export function* fetchPossiblePaymentMethodsRequest(transactionId) {
  try {
    const params = { transactionId };
    const { data } = yield call(() => axios.get(POSSIBLE_PAYMENT_METHODS_API, { params }));
    yield put(fetchPossiblePaymentMethodsSuccess(data));
  } catch (error) {
    yield put(fetchPossiblePaymentMethodsFailure(error.message));
  }
}

export function* fetchPossiblePaymentMethods(action) {
  try {
    const { payload: transactionId } = action;
    fetchPossiblePaymentMethodsRequest(transactionId);
  } catch (error) {
    yield put(fetchPossiblePaymentMethodsFailure(error.message));
  }
}

export function* fetchIframeUrlUserToken(action) {
  try {
    const {
      payload: { scrollToPaymentForm = false, ...rest },
    } = action;

    const { data } = yield call(() => axios.post(IFRAME_URL_USER_TOKEN_API, rest));
    const { javascriptUrl } = data;
    const transactionId = new URL(javascriptUrl).searchParams.get('transactionId');
    yield put(setTransactionId(transactionId));
    yield fetchPossiblePaymentMethodsRequest(transactionId);
    yield put(fetchIframeUrlTokenSuccess(javascriptUrl)); // save { javascriptUrl } in store
    yield put(changeIframeMode(1)); // 1 - edit mode

    if (scrollToPaymentForm) {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });
    }
  } catch (error) {
    yield put(fetchIframeUrlTokenFailure(error.message));
  }
}

export function* getCurrentTokenVersion() {
  try {
    const myRoleType = yield select(getMyRoleType);
    let response = {};
    if (accessRole.includes(myRoleType)) {
      const endUserId = yield select(getEndUserId);
      const params = { endUserId };
      response = yield call(() => axios.get(USER_CURRENT_TOKEN_VERSION_API, { params }));
    } else if (myRoleType === END_USER) {
      response = yield call(() => axios.get(MY_CURRENT_TOKEN_VERSION_API));
    } else {
      throw new Error(i18n.t('roleAccessIsDenied'));
    }
    const { data } = response;
    yield put(fetchCurrentTokenVersionSuccess(data));
  } catch (error) {
    if (error?.response?.data?.statusCode === 404) {
      yield put(fetchCurrentTokenVersionFailure(null));
    } else if (error?.response?.data?.statusCode) {
      yield put(fetchCurrentTokenVersionFailure(null));
      const key = uuidv4();
      yield put(
        addNotification({
          message: error?.response?.data?.message,
          options: {
            key,
            autoHideDuration: 2600,
            variant: 'warning',
          },
        }),
      );
    } else {
      yield put(fetchCurrentTokenVersionFailure(error?.message)); // error
    }
  }
}

export function* onFetchCurrencies() {
  yield takeLatest(FETCH_CURRENCIES_REQUEST, fetchCurrencies);
}

export function* onFetchCurrentSubscription() {
  yield takeLatest(FETCH_IFRAME_URL_TOKEN_REQUEST, fetchIframeUrlUserToken);
}

export function* onGetCurrentTokenVersion() {
  yield takeLatest(FETCH_CURRENT_TOKEN_VERSION_REQUEST, getCurrentTokenVersion);
}

export function* onFetchPossiblePaymentMethods() {
  yield takeLatest(FETCH_POSSIBLE_PAYMENT_METHODS_REQUEST, fetchPossiblePaymentMethods);
}

export default function* CurrenciesSaga() {
  yield all([
    call(onFetchCurrencies),
    call(onFetchCurrentSubscription),
    call(onGetCurrentTokenVersion),
    call(onFetchPossiblePaymentMethods),
  ]);
}
