import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { v4 as uuidv4 } from 'uuid';

import CustomButton from '../../CustomButton';
import BareButton from '../../CustomButton/BareButton';

import { changeCardCredentialsFormLoadingStatus } from '../../PaymentOption/actions';
import {
  changeIframeUserTokenLoadingStatus,
  fetchCurrentSubscriptionRequest,
} from '../../MyPlan/actions';
import { changeIframeMode } from '../../HomePage/actions';
import { addNotification } from '../../Notifications/actions';

import { javascriptUrlSelector } from '../../MyPlan/selectors';
import {
  paymentMethodBrandLogoUrlSelector,
  possiblePaymentMethodsIdSelector,
} from '../../PaymentOption/selectors';
import { loadingSelector as globalLoadingSelector } from '../../LoadingSpinner/selectors';

import i18n from '../../../i18n';

import { containerId, scriptId } from './constants';

import './styles.scss';

const Iframe = (props) => {
  const {
    javascriptUrlData: { javascriptUrl, action },
    changeLoadingStatus,
    changeIframeUserTokenLoading,
    onSuccessChangePlan,
    closeForm,
    passiblePaymentMethodsId,
    globalLoading,
    showError,
    paymentMethodBrandLogoUrl,
  } = props;

  const onSuccessHandlers = {
    addOrUpdateUserToken: closeForm,
    changeSubscriptionProductId: onSuccessChangePlan,
  };

  const onSuccessHandler = onSuccessHandlers[action];

  const paymentMethodConfigurationId = passiblePaymentMethodsId;
  const onLoad = () => {
    const handler = window.IframeCheckoutHandler(paymentMethodConfigurationId);

    handler.setValidationCallback((validationResult) => {
      // You can reset payment errors

      if (validationResult.success) {
        handler.submit(onSuccessHandler);
      } else {
        // Display errors to customer
        validationResult.errors?.forEach((errorMessage) => {
          const key = uuidv4();
          showError({
            message: errorMessage,
            options: {
              key,
              autoHideDuration: 2600,
              variant: 'warning',
            },
          });
        });
      }
    });

    // Set the optional initialize callback
    handler.setInitializeCallback(() => {
      // Execute initialize code
      changeLoadingStatus(false);
      changeIframeUserTokenLoading(false);
    });

    // Set the optional height change callback
    handler.setHeightChangeCallback((height) => {
      document
        .getElementById('walleeContainer')
        .getElementsByTagName('iframe')[0].style.height = `${height}px`;
    });

    handler.create(containerId);

    const confirmTransactionBtn = document.getElementById('pay-button');
    confirmTransactionBtn.onclick = handler.validate;
  };

  const onClean = () => {
    const iframe = document.querySelector(`#${containerId}`);
    if (iframe) {
      iframe.textContent = '';
    }
    const iframeScript = document.querySelector(`#${scriptId}`);
    if (iframeScript) {
      iframeScript.removeEventListener('load', onLoad);
      document.body.removeChild(iframeScript);
      iframeScript.remove();
    }
  };

  useEffect(() => {
    onClean();
    if (javascriptUrl) {
      const script = document.createElement('script');

      script.id = scriptId;
      script.src = javascriptUrl;
      script.async = true;
      script.addEventListener('load', onLoad);

      document.body.appendChild(script);
    }

    return onClean;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [javascriptUrl]);

  return (
    <div className="confirm-iframe-container">
      <div className="confirm-transaction-header">
        <span>{i18n.t('paymentOption')}</span>
      </div>
      <div
        className="wallee-container"
        id="walleeContainer"
      >
        <div className="wallee">
          <div className="iframe-integration">
            <div
              className="iframe-container"
              id={containerId}
            />
          </div>
        </div>
        {paymentMethodBrandLogoUrlSelector ? (
          <img
            className="payment-method-brand-logo"
            src={paymentMethodBrandLogoUrl}
            alt=""
          />
        ) : null}
      </div>
      <div className="confirm-btn-container">
        <CustomButton
          id="pay-button"
          type="button"
          disabled={globalLoading}
        >
          {i18n.t('confirmTransaction').toUpperCase()}
        </CustomButton>
      </div>
      <div className="cancel-confirmation-btn-container">
        <BareButton
          onClick={closeForm}
          disabled={globalLoading}
        >
          {i18n.t('cancelButton').toUpperCase()}
        </BareButton>
      </div>
    </div>
  );
};

Iframe.propTypes = {
  javascriptUrlData: PropTypes.instanceOf(Object).isRequired,
  changeLoadingStatus: PropTypes.func.isRequired,
  changeIframeUserTokenLoading: PropTypes.func.isRequired,
  onSuccessChangePlan: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired,
  passiblePaymentMethodsId: PropTypes.number.isRequired,
  globalLoading: PropTypes.bool.isRequired,
  showError: PropTypes.func.isRequired,
  paymentMethodBrandLogoUrl: PropTypes.string,
};

Iframe.defaultProps = {
  paymentMethodBrandLogoUrl: '',
};

const mapStateToProps = createStructuredSelector({
  javascriptUrlData: javascriptUrlSelector,
  passiblePaymentMethodsId: possiblePaymentMethodsIdSelector,
  globalLoading: globalLoadingSelector,
  paymentMethodBrandLogoUrl: paymentMethodBrandLogoUrlSelector,
});

const mapDispatchToProps = (dispatch) => ({
  changeLoadingStatus: (newStatus) => dispatch(changeCardCredentialsFormLoadingStatus(newStatus)),
  changeIframeUserTokenLoading: (newStatus) =>
    dispatch(changeIframeUserTokenLoadingStatus(newStatus)),
  onSuccessChangePlan: () => dispatch(fetchCurrentSubscriptionRequest()),
  closeForm: () => dispatch(changeIframeMode(0)),
  showError: (props) => dispatch(addNotification(props)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Iframe);
