import React from 'react';
import PropTypes from 'prop-types';

/**
 * Styles
 */
import './styles.scss';

/**
 * Components
 */
import CustomSelect from '../../../CustomSelect';

const Common = (props) => {
  const { isSelected, label, numberOfSelectOptions, input, selectOptionsForSelector } = props;

  if (isSelected) {
    return (
      <div className="d-flex flex-column w-100 voucher-is-selected">
        {numberOfSelectOptions > 1 ? (
          <CustomSelect
            options={selectOptionsForSelector}
            onChange={input?.onChange}
            value={input?.value}
            className="billing-cycle-select"
          />
        ) : (
          <div className="price">{label}</div>
        )}
      </div>
    );
  }

  return <div className="price">{label}</div>;
};

Common.propTypes = {
  selectOptionsForSelector: PropTypes.instanceOf(Array).isRequired,
  input: PropTypes.instanceOf(Object).isRequired,
  isSelected: PropTypes.bool.isRequired,
  numberOfSelectOptions: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
};

export default Common;
