/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { createStructuredSelector } from 'reselect';

import Iframe from './Iframe';

import { loadingSelector } from '../LoadingSpinner/selectors';
import { iframeModeSelector } from '../HomePage/selectors';
import { paymentOptionLoadingSelector } from '../PaymentOption/selectors';

import './styles.scss';

const modalWindowStyle = {
  padding: 0,
  overflow: 'hidden',
};

const ConfirmTransactionModalWindow = (props) => {
  const { globalLoading, iframeMode, ...restProps } = props;
  return (
    <Modal
      {...restProps}
      dialogClassName="confirm-transaction-modal"
      show={iframeMode === 2}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={`confirm-transaction-modal-container ${globalLoading ? 'd-none' : ''}`}
      style={modalWindowStyle}
    >
      <Modal.Body>{iframeMode === 2 ? <Iframe /> : null}</Modal.Body>
    </Modal>
  );
};

ConfirmTransactionModalWindow.propTypes = {
  globalLoading: PropTypes.bool.isRequired,
  iframeMode: PropTypes.number.isRequired,
};

const mapStateToProps = createStructuredSelector({
  globalLoading: loadingSelector,
  iframeMode: iframeModeSelector,
  loading: paymentOptionLoadingSelector,
});

export default connect(mapStateToProps)(ConfirmTransactionModalWindow);
