/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { StyledButton } from './styles';

import { offlineStatusSelector } from '../HomePage/selectors';

const CustomButton = ({ children, disabled, offline, ...otherProps }) => (
  <StyledButton
    type="button"
    disabled={disabled || offline}
    {...otherProps}
  >
    <span>{children}</span>
  </StyledButton>
);

CustomButton.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  offline: PropTypes.bool.isRequired,
};

CustomButton.defaultProps = {
  disabled: false,
};

const mapStateToProps = createStructuredSelector({ offline: offlineStatusSelector });

export default connect(mapStateToProps, null)(CustomButton);
