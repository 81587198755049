import i18n from '../../i18n';

/**
 * Validates required fields
 * @function required
 * @param {string} value Value of field
 * @returns {string|undefined} Returns string with error. Or undefined if value is valid.
 */
export const required = (value) =>
  typeof value !== 'undefined' && value !== null && value !== ''
    ? undefined
    : i18n.t('This field is required');

/**
 * Validates required date value
 * @function validateInputDate
 * @param {Date|string} value Date
 * @returns {string|undefined} Returns string with error. Or undefined if value is valid.
 */
export const date = (value) => {
  return Number.isNaN(Date.parse(value)) ? i18n.t('errorEndDateIsInvalid') : undefined;
};

/**
 * @function lessThanToday
 * @param {Date|string} value Date
 * @returns {string|undefined} Error string if there is an error or undefined if not.
 */
export const lessThanToday = (value) => {
  return Date.parse(value) <= Date.now() ? i18n.t('errorLessThanToday') : undefined;
};

/**
 * @description Validates terms and conditions checkbox
 * @param {any} value
 * @returns {string|undefined}
 */
export const termsAndConditions = (value) => {
  return value === true ? undefined : i18n.t('pleaseAcceptTermsAndConditions');
};
