import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { appConfig } from '../../appConfig';

export const Header = () => {
  const location = new useLocation();
  const { t } = useTranslation();
  const app = new URLSearchParams(location.search).get('app');
  const redirectUrl = new URLSearchParams(location.search).get('redirectUrl');

  if (!app || app !== 'web') return null;

  return (
    <Box
      sx={{
        backgroundColor: '#FFFFFF',
        height: 60,
        px: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        top: 0,
        position: 'sticky',
        zIndex: 1,
      }}
    >
      <Typography
        variant="h6"
        component="h1"
        sx={{ color: '#161B2B' }}
        fontSize={18}
        fontFamily={'Poppins'}
      >
        {t('payments')}
      </Typography>
      <Button
        variant="contained"
        href={redirectUrl ? decodeURIComponent(redirectUrl) : appConfig.REACT_APP_MAIN_FRONT}
        color="info"
        sx={{
          fontFamily: 'Poppins',
          borderRadius: '55px',
          height: '30px',
          color: '#565a65',
          border: '1px solid',
          borderColor: '#565a65',
          textTransform: 'capitalize',
          backgroundColor: 'transparent',
          boxShadow: 'none',
          '&:hover,&:active,&:focus': {
            backgroundColor: 'transparent',
            color: '#565a65',
          },
        }}
      >
        {t('back')}
      </Button>
    </Box>
  );
};
