export const SET_USER_JWT_TOKEN = 'SET_USER_JWT_TOKEN';
export const SET_END_USER_ID = 'SET_END_USER_ID';
export const FETCH_ME_REQUEST = 'FETCH_ME_REQUEST';
export const FETCH_ME_SUCCESS = 'FETCH_ME_SUCCESS';
export const FETCH_ME_FAILURE = 'FETCH_ME_FAILURE';
export const SET_APP_TYPE = 'SET_APP_TYPE';
export const FETCH_IS_SUBSCRIPTION_PRODUCT_CHANGE_ALLOWED_REQUEST =
  'FETCH_IS_SUBSCRIPTION_PRODUCT_CHANGE_ALLOWED_REQUEST';
export const FETCH_IS_SUBSCRIPTION_PRODUCT_CHANGE_ALLOWED_SUCCESS =
  'FETCH_IS_SUBSCRIPTION_PRODUCT_CHANGE_ALLOWED_SUCCESS';
export const FETCH_IS_SUBSCRIPTION_PRODUCT_CHANGE_ALLOWED_FAILURE =
  'FETCH_IS_SUBSCRIPTION_PRODUCT_CHANGE_ALLOWED_FAILURE';
export const FETCH_TRANSACTION_ERROR = 'FETCH_TRANSACTION_ERROR';
