export const FETCH_CURRENCIES_REQUEST = 'FETCH_CURRENCIES_REQUEST';
export const FETCH_CURRENCIES_SUCCESS = 'FETCH_CURRENCIES_SUCCESS';
export const FETCH_CURRENCIES_FAILURE = 'FETCH_CURRENCIES_FAILURE';
export const FETCH_CARD_CREDENTIALS_FORM = 'FETCH_CARD_CREDENTIALS_FORM';
export const CHANGE_CARD_CREDENTIALS_FORM_LOADING_STATUS =
  'CHANGE_CARD_CREDENTIALS_FORM_LOADING_STATUS';
export const CHANGE_CURRENCY = 'CHANGE_CURRENCY';
export const SET_TRANSACTION_ID = 'SET_TRANSACTION_ID';
export const FETCH_CURRENT_TOKEN_VERSION_REQUEST = 'FETCH_CURRENT_TOKEN_VERSION_REQUEST';
export const FETCH_CURRENT_TOKEN_VERSION_SUCCESS = 'FETCH_CURRENT_TOKEN_VERSION_SUCCESS';
export const FETCH_CURRENT_TOKEN_VERSION_FAILURE = 'FETCH_CURRENT_TOKEN_VERSION_FAILURE';
export const CHANGE_IFRAME_CURRENCY_SELECT_STATUS = 'CHANGE_IFRAME_CURRENCY_SELECT_STATUS';
export const FETCH_POSSIBLE_PAYMENT_METHODS_REQUEST = 'FETCH_POSSIBLE_PAYMENT_METHODS_REQUEST';
export const FETCH_POSSIBLE_PAYMENT_METHODS_SUCCESS = 'FETCH_POSSIBLE_PAYMENT_METHODS_SUCCESS';
export const FETCH_POSSIBLE_PAYMENT_METHODS_FAILURE = 'FETCH_POSSIBLE_PAYMENT_METHODS_FAILURE';
