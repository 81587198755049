import { ADD_PAYMENT_ERROR_MESSAGE, REMOVE_PAYMENT_ERROR_MESSAGE } from './types';

const INITIAL_STATE = {
  message: '',
  scrollToMessage: {
    enable: false,
    delay: 500,
    timestamp: 0,
  },
};

const paymentErrorReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_PAYMENT_ERROR_MESSAGE:
      return {
        ...state,
        message: action.payload.message,
        scrollToMessage: {
          ...action.payload.scrollToMessage,
          timestamp: Date.now(),
        },
      };
    case REMOVE_PAYMENT_ERROR_MESSAGE:
      return {
        ...state,
        message: '',
        scrollToMessage: {
          enable: false,
          delay: 500,
          timestamp: 0,
        },
      };
    default:
      return state;
  }
};

export default paymentErrorReducer;
