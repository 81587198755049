import axios from '../api';

import i18n from './index';

import { defaultLanguage, defaultLanguageSymbol, languages } from '../constants';

export const setLanguage = (language) => {
  axios.defaults.headers.common['Accept-Language'] = languages?.[language] || defaultLanguageSymbol;
  i18n.changeLanguage(language || defaultLanguage);
};
