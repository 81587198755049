import { all, call, takeLatest } from '@redux-saga/core/effects';

import { CLOSE_THANKS_MODAL } from './types';

import { fetchCurrentSubscriptionSaga } from '../MyPlan/sagas';

export function* onCloseThanksModal() {
  yield takeLatest(CLOSE_THANKS_MODAL, fetchCurrentSubscriptionSaga);
}

export default function* thanksModalSaga() {
  yield all([call(onCloseThanksModal)]);
}
