import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import plansTableReducer from '../components/PlansTable/reducer';
import paymentOptionReducer from '../components/PaymentOption/reducer';
import myPlanReducer from '../components/MyPlan/reducer';
import notificationsReducer from '../components/Notifications/reducer';
import thanksModalReducer from '../components/ThanksModal/reducer';
import authorizationReducer from '../components/AuthorizationApp/reducer';
import userReducer from '../components/HomePage/reducer';
import paymentErrorReducer from '../components/PaymentError/reducer';

export default combineReducers({
  form: formReducer,
  plansTable: plansTableReducer,
  paymentOption: paymentOptionReducer,
  myPlan: myPlanReducer,
  notifications: notificationsReducer,
  thanksModal: thanksModalReducer,
  authorization: authorizationReducer,
  user: userReducer,
  paymentError: paymentErrorReducer,
});
