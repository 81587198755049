import {
  SET_USER_JWT_TOKEN,
  SET_END_USER_ID,
  FETCH_ME_REQUEST,
  FETCH_ME_SUCCESS,
  FETCH_ME_FAILURE,
  SET_APP_TYPE,
  FETCH_IS_SUBSCRIPTION_PRODUCT_CHANGE_ALLOWED_REQUEST,
  FETCH_IS_SUBSCRIPTION_PRODUCT_CHANGE_ALLOWED_SUCCESS,
  FETCH_IS_SUBSCRIPTION_PRODUCT_CHANGE_ALLOWED_FAILURE,
} from './types';

const initialState = {
  jwt: '',
  endUserId: '',
  me: null,
  app: '',
  isSubscriptionProductChangeAllowed: false,
  errors: {
    fetchMeError: null,
  },
  allLoading: {
    fetchMeLoading: false,
  },
  notImportantErrors: {
    isSubscriptionProductChangeAllowedError: null,
  },
  notImportantLoading: {
    isSubscriptionProductChangeAllowedLoading: false,
  },
};

const authorizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_JWT_TOKEN:
      return {
        ...state,
        jwt: action.payload.jwtToken,
      };
    case SET_END_USER_ID:
      return {
        ...state,
        endUserId: action.payload.endUserId,
      };
    case FETCH_ME_REQUEST:
      return {
        ...state,
        errors: {
          ...state.errors,
          fetchMeError: null,
        },
        allLoading: {
          ...state.allLoading,
          fetchMeLoading: true,
        },
      };
    case FETCH_ME_SUCCESS:
      return {
        ...state,
        me: action.payload.data,
        errors: {
          ...state.errors,
          fetchMeError: null,
        },
        allLoading: {
          ...state.allLoading,
          fetchMeLoading: false,
        },
      };
    case FETCH_ME_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          fetchMeError: action.payload.errorMessage,
        },
        allLoading: {
          ...state.allLoading,
          fetchMeLoading: false,
        },
      };
    case SET_APP_TYPE:
      return {
        ...state,
        app: action.payload.appType,
      };
    case FETCH_IS_SUBSCRIPTION_PRODUCT_CHANGE_ALLOWED_REQUEST:
      return {
        ...state,
        notImportantErrors: {
          ...state.notImportantErrors,
          isSubscriptionProductChangeAllowedError: null,
        },
        notImportantLoading: {
          ...state.notImportantLoading,
          isSubscriptionProductChangeAllowedLoading: true,
        },
      };
    case FETCH_IS_SUBSCRIPTION_PRODUCT_CHANGE_ALLOWED_SUCCESS:
      return {
        ...state,
        isSubscriptionProductChangeAllowed: action.payload.allowed,
        notImportantErrors: {
          ...state.notImportantErrors,
          isSubscriptionProductChangeAllowedError: null,
        },
        notImportantLoading: {
          ...state.notImportantLoading,
          isSubscriptionProductChangeAllowedLoading: false,
        },
      };
    case FETCH_IS_SUBSCRIPTION_PRODUCT_CHANGE_ALLOWED_FAILURE:
      return {
        ...state,
        notImportantErrors: {
          ...state.notImportantErrors,
          isSubscriptionProductChangeAllowedError: action.payload.errorMessage,
        },
        notImportantLoading: {
          ...state.notImportantLoading,
          isSubscriptionProductChangeAllowedLoading: false,
        },
      };
    default:
      return state;
  }
};

export default authorizationReducer;
