import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

/**
 * Components
 */
import RadioInput from './components/RadioInput';

/**
 * Styles
 */
import './styles.scss';

const RadioButtons = (props) => {
  const { variants, currentProductVersion, input, ...custom } = props;

  const allOptions = variants.map(({ radioButtons }) => radioButtons).flat(2);

  const selectedOption =
    allOptions.find((option) => option?._id === input?.value) ||
    allOptions.find(({ _id }) => _id === currentProductVersion._id);

  return variants.map((variant) => (
    <Fragment key={variant.type}>
      <div className="header-container">
        <span className="header">{variant.title}</span>
      </div>
      {variant?.radioButtons?.map((selectOptions, idx) => (
        <RadioInput
          // eslint-disable-next-line react/no-array-index-key
          key={`${variant.type}_${idx}`}
          selectOptions={selectOptions}
          type={variant.type}
          selected={selectedOption?._id}
          input={input}
          {...custom}
        />
      ))}
    </Fragment>
  ));
};

RadioButtons.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  currentProductVersion: PropTypes.instanceOf(Object).isRequired,
  variants: PropTypes.instanceOf(Array).isRequired,
};

export default RadioButtons;
