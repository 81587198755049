import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { directMarketingSelector } from './selectors';
import i18n from '../../i18n';
import './style.scss';
import { directMarketingValues } from './constants';

import { END_USER } from '../../constants';

const SolarTariff = (props) => {
  const { directMarketing, myRoleType } = props;

  //! END_USER]is a role type that is not allowed to see the tariff 'noSolarTariff'
  if ([END_USER].includes(myRoleType) && directMarketing === 0) {
    return null;
  }

  return (
    <div className="webview-component">
      <span className="label">{i18n.t('helionSolarTariff')}</span>
      <div className="d-flex justify-content-between data-wrapper">
        <span className="label">{i18n.t('chosenTariff')}:</span>
        <span className="value">{i18n.t(directMarketingValues[directMarketing])}</span>
      </div>
    </div>
  );
};

SolarTariff.propTypes = {
  directMarketing: PropTypes.number.isRequired,
  myRoleType: PropTypes.string,
};

SolarTariff.defaultProps = {
  myRoleType: null,
};

const mapStateToProps = createStructuredSelector({
  directMarketing: directMarketingSelector,
});

export default connect(mapStateToProps, null)(SolarTariff);
