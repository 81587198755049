import { createSelector } from 'reselect';
import { BILLING_CYCLE, freePlan } from '../../constants';

export const getAllLoading = (state) => state.plansTable.allLoading;
export const getErrors = (state) => state?.plansTable?.errors;
export const getData = (state) => state?.plansTable?.data;
export const getBenefits = (state) => state?.plansTable?.benefits;

export const plansTableDataSelector =
  createSelector([getData], (data) =>
    data?.filter(
      ({ product }) => product.billingCycle === BILLING_CYCLE.MONTH || product.type === freePlan,
    ),
  ) || [];
export const benefitsTableDataSelector = createSelector(
  [getBenefits],
  (benefitsData) => benefitsData || {},
);

export const loadingSelector = createSelector([getAllLoading], (loadingObj) =>
  Object.values(loadingObj).some((loading) => loading),
);
