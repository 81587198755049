import {
  FETCH_ALL_PAYMENT_METHOD_BRANDS_REQUEST,
  FETCH_ALL_PAYMENT_METHOD_BRANDS_SUCCESS,
  FETCH_ALL_PAYMENT_METHOD_BRANDS_FAILURE,
  UPDATE_CURRENCY_REQUEST,
  UPDATE_CURRENCY_SUCCESS,
  UPDATE_CURRENCY_FAILURE,
  CHANGE_IFRAME_MODE,
  CHANGE_OFFLINE_STATUS,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
} from './types';

const INITIAL_STATE = {
  allPaymentMethodBrands: [],
  iframeMode: 0,
  user: null,
  errors: {
    allPaymentMethodsError: null,
    updateCurrencyError: null,
    fetchUserError: null,
  },
  allLoading: {
    allPaymentMethodsLoading: false,
    updateCurrencyLoading: false,
    fetchUserLoading: false,
  },
  offline: false,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_PAYMENT_METHOD_BRANDS_REQUEST:
      return {
        ...state,
        allPaymentMethodBrands: [],
        errors: {
          ...state.errors,
          allPaymentMethodsError: null,
        },
        allLoading: {
          ...state.allLoading,
          allPaymentMethodsLoading: true,
        },
      };
    case FETCH_ALL_PAYMENT_METHOD_BRANDS_SUCCESS:
      return {
        ...state,
        allPaymentMethodBrands: action.payload.data,
        errors: {
          ...state.errors,
          allPaymentMethodsError: null,
        },
        allLoading: {
          ...state.allLoading,
          allPaymentMethodsLoading: false,
        },
      };
    case FETCH_ALL_PAYMENT_METHOD_BRANDS_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          allPaymentMethodsError: action.payload.errorMessage,
        },
        allLoading: {
          ...state.allLoading,
          allPaymentMethodsLoading: false,
        },
      };
    case UPDATE_CURRENCY_REQUEST:
      return {
        ...state,
        errors: {
          ...state.errors,
          updateCurrencyError: null,
        },
        allLoading: {
          ...state.allLoading,
          updateCurrencyLoading: true,
        },
      };
    case UPDATE_CURRENCY_SUCCESS:
      return {
        ...state,
        errors: {
          ...state.errors,
          updateCurrencyError: null,
        },
        allLoading: {
          ...state.allLoading,
          updateCurrencyLoading: false,
        },
      };
    case UPDATE_CURRENCY_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          updateCurrencyError: action.payload.errorMessage,
        },
        allLoading: {
          ...state.allLoading,
          updateCurrencyLoading: false,
        },
      };
    case CHANGE_IFRAME_MODE:
      return {
        ...state,
        iframeMode: action.payload.mode,
      };
    case CHANGE_OFFLINE_STATUS:
      return {
        ...state,
        offline: action.payload.newStatus,
      };
    case FETCH_USER_REQUEST:
      return {
        ...state,
        errors: {
          ...state.errors,
          fetchUserError: null,
        },
        allLoading: {
          ...state.allLoading,
          fetchUserLoading: true,
        },
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        user: action.payload.data,
        errors: {
          ...state.errors,
          fetchUserError: null,
        },
        allLoading: {
          ...state.allLoading,
          fetchUserLoading: false,
        },
      };
    case FETCH_USER_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          fetchUserError: action.payload.errorMessage,
        },
        allLoading: {
          ...state.allLoading,
          fetchUserLoading: false,
        },
      };
    default:
      return state;
  }
};

export default userReducer;
