import { all, call, takeLatest, put } from '@redux-saga/core/effects';
import axios from '../../api';

import {
  SUBSCRIPTION_CURRENT_PRODUCT_VERSIONS_API,
  SUBSCRIPTION_BENEFITS_API,
} from '../../api/endpoints';

import types from './types';

import { fetchPlansSuccess, fetchPlansFailure, fetchBenefitsSuccess } from './actions';

export function* fetchPlans(action) {
  try {
    const {
      payload: { userId },
    } = action;
    const { data } = yield call(() =>
      axios.get(`${SUBSCRIPTION_CURRENT_PRODUCT_VERSIONS_API}/${userId}`),
    );
    yield put(fetchPlansSuccess(data));
  } catch (error) {
    yield put(fetchPlansFailure(error.message));
  }
}
export function* fetchBenefits() {
  try {
    const { data } = yield call(() => axios.get(SUBSCRIPTION_BENEFITS_API));
    yield put(fetchBenefitsSuccess(data));
  } catch {
    //TODO: add error logic
  }
}

export function* onFetchPlans() {
  yield takeLatest(types.FETCH_PLANS_REQUEST, fetchPlans);
}

export function* onFetchBenefits() {
  yield takeLatest(types.FETCH_PLANS_REQUEST, fetchBenefits);
}

export default function* plansSaga() {
  yield all([call(onFetchPlans), call(onFetchBenefits)]);
}
