import React from 'react';
import PropTypes from 'prop-types';
import { NativeSelect } from '@mui/material';

import { ReactComponent as Checkmark } from '../../assets/images/checkmark.svg';

import './styles.scss';

const CustomSelect = (props) => {
  const { options, value, onChange, className } = props;

  const changeHandler = ({ target }) => {
    onChange(target?.value);
  };

  return (
    <NativeSelect
      name="custom-select"
      value={value}
      className={`custom-select-container ${className}`}
      IconComponent={Checkmark}
      onChange={changeHandler}
      classes={{
        select: 'custom-select',
        icon: 'custom-select-icon',
      }}
      fullWidth
    >
      {options.map((option) => (
        <option
          key={option.value}
          value={option.value}
        >
          {option.label}
        </option>
      ))}
    </NativeSelect>
  );
};

CustomSelect.propTypes = {
  options: PropTypes.instanceOf(Array),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
  className: PropTypes.string,
};

CustomSelect.defaultProps = {
  value: null,
  className: '',
  options: [],
  onChange: () => {},
};

export default CustomSelect;
