import React from 'react';
import PropTypes from 'prop-types';
import { upperCase } from 'lodash';
import moment from 'moment';

/**
 * Styles
 */
import './styles.scss';

/**
 * Internationalization
 */
import i18n from '../../../../i18n';

const VoucherIsNotSelected = (props) => {
  const { label, endDate } = props;

  return (
    <div className="d-flex flex-column align-items-end w-100">
      <div className="plan-label d-flex justify-content-end">
        {i18n.t('plan')}:<span>{endDate && label ? upperCase(label) : i18n.t('notSelected')}</span>
      </div>
      <div className="end-on-label">
        {i18n.t('endOn')}:
        <span>{endDate ? moment(endDate).format('DD/MM/yy') : i18n.t('noDate')}</span>
      </div>
    </div>
  );
};

VoucherIsNotSelected.propTypes = {
  label: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

export default VoucherIsNotSelected;
