import { all, call } from '@redux-saga/core/effects';

import plansSaga from '../components/PlansTable/sagas';
import myPlanSaga from '../components/MyPlan/sagas';
import paymentOptionSaga from '../components/PaymentOption/sagas';
import userSaga from '../components/HomePage/sagas';
import authorizationSaga from '../components/AuthorizationApp/sagas';
import thanksModalSaga from '../components/ThanksModal/sagas';

export default function* rootSaga() {
  yield all([
    call(plansSaga),
    call(myPlanSaga),
    call(paymentOptionSaga),
    call(userSaga),
    call(authorizationSaga),
    call(thanksModalSaga),
  ]);
}
