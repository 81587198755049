import { createSelector } from 'reselect';

import { getErrors as getPlansTableErrors } from '../PlansTable/selectors';
import { getErrors as getMyPlanErrors } from '../MyPlan/selectors';
import { getErrors as getPaymentOptionErrors } from '../PaymentOption/selectors';
import { getErrors as getAuthorizationErrors } from '../AuthorizationApp/selectors';

export const errorsSelector = createSelector(
  [getPlansTableErrors, getMyPlanErrors, getPaymentOptionErrors, getAuthorizationErrors],
  (plansTableErrors, myPlanErrors, paymentOptionErrors, authorizationErrors) =>
    [
      ...Object.values(plansTableErrors),
      ...Object.values(myPlanErrors),
      ...Object.values(paymentOptionErrors),
      ...Object.values(authorizationErrors),
    ].some((error) => error && error !== 'Network Error'),
);
