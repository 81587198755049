import { createSelector } from 'reselect';

import { getAllPaymentMethodBrands, getIframeMode } from '../HomePage/selectors';

import { allCurrencyOptions, DATA_COLLECTION_TYPES } from '../../constants';
import moment from 'moment';

export const getCurrenciesList = (state) => state?.paymentOption?.currencies;
export const getCardNumberSelector = (state) =>
  state?.paymentOption?.cardCredentialsForm?.data?.cardNumber;
export const getCardExpiryData = (state) =>
  state?.paymentOption?.cardCredentialsForm?.data?.expiryDate;
export const getCardCredentials = (state) => state?.form?.cardCredentialsForm?.values;
export const getFormInitialValues = (state) => state?.paymentOption?.cardCredentialsForm?.data;
export const getAllLoading = (state) => state?.paymentOption?.allLoading;
export const getErrors = (state) => state?.paymentOption?.errors;
export const getTransactionID = (state) => state?.paymentOption?.transactionId;
export const getCurrentTokenVersion = (state) => state?.paymentOption?.currentTokenVersion;
export const getIframeCurrencySelectStatus = (state) =>
  state?.paymentOption?.showIframeCurrencySelect;
export const getPossiblePaymentMethods = (state) => state?.paymentOption?.possiblePaymentMethods;

export const paymentOptionLoadingSelector = createSelector([getAllLoading], (loading) => loading);

export const possiblePaymentMethodsIdSelector = createSelector(
  [getPossiblePaymentMethods],
  (data) =>
    Array.isArray(data)
      ? data.find(
          (paymentMethod) => paymentMethod.dataCollectionType === DATA_COLLECTION_TYPES.ONSITE,
        )?.id
      : null,
);

export const iframeCurrencySelectStatusSelector = createSelector(
  [getIframeCurrencySelectStatus],
  (show) => show,
);

export const currentTokenVersionSelector = createSelector(
  [getCurrentTokenVersion],
  (currentTokenVersion) => {
    const cardNumberString = currentTokenVersion?.name;
    const expiresOnString = currentTokenVersion?.expiresOn;
    if (!currentTokenVersion || !cardNumberString) {
      return null;
    }
    const expiresOn = expiresOnString ? moment(expiresOnString).format('MM/YYYY') : null;
    const cardNumber = cardNumberString.split(' ').slice(-2).join(' ').replace(/x/gim, '*');
    const paymentMethodBrand = currentTokenVersion?.paymentMethodBrand || null;
    const state = currentTokenVersion?.state || null;
    return {
      cardNumber,
      expiresOn,
      paymentMethodBrand,
      state,
    };
  },
);

export const transactionIdSelector = createSelector(
  [getTransactionID],
  (transactionId) => transactionId,
);

export const currencyOptionsSelector = createSelector([getCurrenciesList], (currencies) => {
  if (!Array.isArray(currencies) && !currencies?.length) {
    return [];
  }
  return allCurrencyOptions.filter(({ value }) =>
    currencies.some(({ currencyCode }) => currencyCode === value),
  );
});

export const cardNumberSelector = createSelector([getCardNumberSelector], (cardNumber) =>
  cardNumber ? `**** ${cardNumber.slice(-4)}` : '',
);

export const cardExpiredDateSelector = createSelector(
  [getCardExpiryData],
  (expiryDate) => expiryDate || '',
);

export const cardCredentialsSelector = createSelector(
  [getCardCredentials],
  (credentials) => credentials,
);

export const formInitialValuesSelector = createSelector(
  [getFormInitialValues],
  (initialValues) => initialValues,
);

export const showEditSelector = createSelector(
  [currentTokenVersionSelector, getIframeMode],
  (data, iframeMode) => (iframeMode === 0 || iframeMode === 2) && !!data,
);

export const paymentMethodBrandLogoUrlSelector = createSelector(
  [getCurrentTokenVersion, getAllPaymentMethodBrands],
  (currentTokenVersion, allPaymentMethodBrands) => {
    const { paymentMethodBrand, linkedSpaceId } = currentTokenVersion || {};
    if (allPaymentMethodBrands && paymentMethodBrand && linkedSpaceId) {
      const imagePath = allPaymentMethodBrands.find(
        ({ id }) => id === paymentMethodBrand,
      )?.imagePath;
      if (imagePath) {
        return `https://app-wallee.com/s/${linkedSpaceId}/resource${imagePath}`;
      }
      return '';
    }
    return '';
  },
);
