import _ from 'lodash';
import { createSelector } from 'reselect';

/**
 * Constants
 */
import { currencySymbols, defaultCurrency, PV_INSTALLER, ROOT, SOLAR_ADMIN } from '../../constants';
import i18n from '../../i18n';

/**
 * Selectors
 */
import { getMyRoleType } from '../AuthorizationApp/selectors';
import { getUserCurrency } from '../HomePage/selectors';
import { getCurrentPlan } from '../MyPlan/selectors';

/**
 * Utils
 */
import { generateFormattedPrice, generateLabel, compareByYear, getPlans } from './utils';

export const getPlansList = (state) => state?.plansTable?.data;
export const getSelectedPlan = (state) => state?.form?.changePlanForm?.values?.plans;
export const getEndDateSelectedPlan = (state) => state?.form?.changePlanForm?.values?.endDate;

export const radioButtonsSelector = createSelector(
  [getPlansList, getUserCurrency, getCurrentPlan, getMyRoleType],
  (plansList, userCurrency, currentPlan, myRoleType) => {
    const { vouchers, subscriptions } = getPlans(plansList);

    /**
     * Common
     */
    const commonProductVersionsInterface = {
      title: i18n.t('choosePlan'),
      type: 'initial|trial',
      radioButtons: [],
    };

    const commonProductVersionsSelectOptions = subscriptions.reduce(
      (radioButtons, productVersion) => {
        const { product, fee, type: productVersionType, _id } = productVersion;

        const currentCurrency = userCurrency || defaultCurrency;
        const currencyObject = fee.find((item) => item.currency === currentCurrency);
        const { currency, amount } = currencyObject;

        const { description, subDescription, billingCycle, type: productType } = product;

        const option = {
          /**
           * Product version _id
           */
          _id,
          /**
           * Example: Free, Year: 1.00 CHF
           */
          label: generateLabel(billingCycle, amount, currency),
          productVersionType,
          productVersionFamily: {
            type: commonProductVersionsInterface.type,
          },
          /**
           * Product type. Example: "helion_free", "helion_basic", "helion_pro"
           */
          productType,
          /**
           * Description. Example: "Subscription per", "Subscription (unlimited)"
           */
          description,
          /**
           * Sub description. Example: "The first three month are free", "The first year is free"
           */
          subDescription,
          /**
           * YEAR or MONTH
           */
          billingCycle,
        };

        return [...radioButtons, option];
      },
      [],
    );

    const groupedCommonProductVersionsSelectOptions = _.groupBy(
      commonProductVersionsSelectOptions,
      (option) => option.productType,
    );

    /**
     * Sort in ascending order, lower values at the top, larger values at the bottom
     */
    const commonSortOrder = {
      helion_free: 0,
      helion_basic: 1,
      helion_pro: 2,
    };

    const commonRadioButtons = Object.entries(groupedCommonProductVersionsSelectOptions)
      .sort(
        ([productTypeA], [productTypeB]) =>
          commonSortOrder[productTypeA] - commonSortOrder[productTypeB],
      )
      .map(([, selectOptions]) =>
        selectOptions.sort((a, b) => compareByYear(a?.billingCycle, b?.billingCycle)),
      );

    commonProductVersionsInterface.radioButtons = commonRadioButtons;

    /**
     * Voucher
     */
    const voucherProductVersionsInterface = {
      title: i18n.t('voucherPlan'),
      type: 'voucher',
      radioButtons: [],
    };

    const voucherProductVersionsSelectOptions = vouchers.reduce((radioButtons, productVersion) => {
      const { product, type: productVersionType, _id } = productVersion;

      const { name, billingCycle, type: productType } = product;

      const option = {
        /**
         * Product version _id
         */
        _id,
        /**
         * Example: BASIC, PRO
         */
        label: name.toUpperCase(),
        productVersionType,
        productVersionFamily: {
          type: voucherProductVersionsInterface.type,
        },
        /**
         * Product type
         */
        productType,
        /**
         * Billing cycle for product. Example: "YEAR", "MONTH"
         */
        billingCycle,
        /**
         * End date for voucher.
         */
        endDate: currentPlan?.endDate || '',
        allowedToChangeEndDate: [ROOT, SOLAR_ADMIN, PV_INSTALLER].includes(myRoleType),
      };

      return [...radioButtons, option];
    }, []);

    const groupedVoucherProductVersionsSelectOptions = _.groupBy(
      voucherProductVersionsSelectOptions,
      (option) => option.productVersionType,
    );

    /**
     * Sort in ascending order, lower values at the top, larger values at the bottom
     */
    const voucherSortOrder = {
      helion_basic: 0,
      helion_pro: 1,
    };

    const voucherRadioButtons = Object.entries(groupedVoucherProductVersionsSelectOptions)
      .sort(
        ([productTypeA], [productTypeB]) =>
          voucherSortOrder[productTypeA] - voucherSortOrder[productTypeB],
      )
      .map(([, selectOptions]) => selectOptions);

    voucherProductVersionsInterface.radioButtons = voucherRadioButtons;

    // Omit empty radio button groups
    return [commonProductVersionsInterface, voucherProductVersionsInterface].filter(
      (radioButtonsGroup) => radioButtonsGroup?.radioButtons?.length,
    );
  },
);

export const selectedPlanSelector = createSelector(
  [getPlansList, getSelectedPlan, getUserCurrency, getCurrentPlan, getEndDateSelectedPlan],
  (plansList, selectedPlanId, userCurrency, currentPlan, endDate) => {
    const currentCurrency = userCurrency || defaultCurrency;
    const { subscriptions, vouchers } = getPlans(plansList);
    const planToFind =
      currentPlan?.product?.type &&
      Array.isArray(subscriptions) &&
      [...subscriptions, ...vouchers].find((plan) => plan?._id === selectedPlanId);

    if (planToFind) {
      const { currency, amount } = planToFind.fee.find((item) => item.currency === currentCurrency);
      const {
        type: planType,
        _id: productVersionId,
        product: {
          _id,
          name,
          billingCycle,
          type: productType,
          description,
          subDescription,
          isProductAllowed = true,
        },
      } = planToFind;
      return {
        id: _id,
        label: name.toLowerCase(),
        planType,
        endDate:
          endDate && !Number.isNaN(Date.parse(endDate))
            ? new Date(endDate).toISOString()
            : undefined,
        productType,
        className: name.toLowerCase(),
        price: generateFormattedPrice(amount, currency),
        billingCycle: billingCycle?.toLowerCase() || '',
        pricePer:
          amount === 0
            ? `${amount} ${currencySymbols[currency]}`
            : `${Number.parseFloat(amount).toFixed(2)} ${currencySymbols[currency]}`,
        description,
        subDescription,
        isProductAllowed,
        productVersionId,
      };
    }
    return {
      id: '',
      label: '',
      className: '',
      price: '',
      planType: '',
      productType: '',
      description: '',
      subDescription: '',
      isProductAllowed: false,
    };
  },
);
