import i18n from '../i18n';

export const currencySymbols = {
  CHF: i18n.t('chfSymbol'),
  EUR: i18n.t('eurSymbol'),
};

export const allCurrencyOptions = [
  {
    value: 'EUR',
    label: `${i18n.t('eurSymbol')} (${i18n.t('eur')})`,
  },
  {
    value: 'CHF',
    label: i18n.t('chf'),
  },
];

/**
 * Default currency
 */
export const defaultCurrency = 'CHF';

export const accessRole = [
  'root',
  'solar_admin',
  'support',
  'pv_installer',
  'pv_installer_employee',
  'pv_installer_employee_read_only',
];

export const END_USER = 'end_user';
export const SOLAR_ADMIN = 'solar_admin';
export const ROOT = 'root';
export const SUPPORT = 'support';
export const PV_INSTALLER = 'pv_installer';
export const PV_INSTALLER_EMPLOYEE = 'pv_installer_employee';
export const PV_INSTALLER_EMPLOYEE_READ_ONLY = 'pv_installer_employee_read_only';

/**
 * Default language
 */
export const defaultLanguage = 'English';

/**
 * Language -> language shorten symbol for Accept-Language header
 */
export const languages = {
  English: 'en',
  French: 'fr',
  Deutsch: 'de',
  Italian: 'it',
  Danish: 'da',
};

/**
 * Default shorten symbol for Accept-Language header
 */
export const defaultLanguageSymbol = 'en';

/**
 * Priority of displaying plans in radio-buttons
 */
export const priorityForPlansOptions = {
  free: 1,
  basic: 2,
  pro: 3,
};

/**
 * Name of web page type, which should display
 */
export const webApp = 'web';

/**
 * Product type Helion Free
 */
export const freePlan = 'helion_free';

/**
 * Product type Helion Basic
 */
export const basicPlan = 'helion_basic';

/**
 * Product type Helion Pro
 */
export const proPlan = 'helion_pro';

/**
 * Trial plan type
 */
export const trialPlanType = 'trial';

/**
 * Voucher plan type
 */
export const voucherPlan = 'voucher';

/**
 * Initial plan type
 */
export const initialPlanType = 'initial';

export const BILLING_CYCLE = {
  YEAR: 'YEAR',
  MONTH: 'MONTH',
};

/**
 * The data collection type determines who is collecting the payment information.
 * This can be done either by the processor (offsite) or by our application (onsite).
 */
export const DATA_COLLECTION_TYPES = {
  ONSITE: 'ONSITE',
  OFFSITE: 'OFFSITE',
};
