import {
  FETCH_CURRENCIES_REQUEST,
  FETCH_CURRENCIES_SUCCESS,
  FETCH_CURRENCIES_FAILURE,
  FETCH_CARD_CREDENTIALS_FORM,
  CHANGE_CARD_CREDENTIALS_FORM_LOADING_STATUS,
  CHANGE_CURRENCY,
  SET_TRANSACTION_ID,
  FETCH_CURRENT_TOKEN_VERSION_REQUEST,
  FETCH_CURRENT_TOKEN_VERSION_SUCCESS,
  FETCH_CURRENT_TOKEN_VERSION_FAILURE,
  CHANGE_IFRAME_CURRENCY_SELECT_STATUS,
  FETCH_POSSIBLE_PAYMENT_METHODS_REQUEST,
  FETCH_POSSIBLE_PAYMENT_METHODS_SUCCESS,
  FETCH_POSSIBLE_PAYMENT_METHODS_FAILURE,
} from './types';

const INITIAL_STATE = {
  allLoading: {
    fetchCurrenciesRequestLoading: false,
    fetchCardCredentialsFormLoading: false,
    fetchCurrentTokenVersionRequestLoading: false,
    fetchPossiblePaymentMethodsLoading: false,
  },
  errors: {
    fetchCurrenciesRequestError: null,
    fetchCurrentTokenVersionRequestError: null,
    fetchPossiblePaymentMethodsError: null,
  },
  currencies: [],
  changePlanWindow: {
    active: false,
  },
  paymentCredentials: {
    card: {
      number: '',
      expired: {
        month: null,
        year: null,
      },
    },
  },
  paymentOptionAdded: false,
  cardCredentialsForm: {
    data: {
      cardNumber: '',
      expiryDate: '',
      cvv: '',
      currency: '',
    },
  },
  transactionId: '',
  currentTokenVersion: null,
  showIframeCurrencySelect: false,
  possiblePaymentMethods: [],
};

const paymentOptionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CURRENCIES_REQUEST:
      return {
        ...state,
        errors: {
          ...state.errors,
          fetchCurrenciesRequestError: null,
        },
        allLoading: {
          ...state.allLoading,
          fetchCurrenciesRequestLoading: true,
        },
      };
    case FETCH_CURRENCIES_SUCCESS:
      return {
        ...state,
        currencies: action.payload.currencies,
        errors: {
          ...state.errors,
          fetchCurrenciesRequestError: null,
        },
        allLoading: {
          ...state.allLoading,
          fetchCurrenciesRequestLoading: false,
        },
      };
    case FETCH_CURRENCIES_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          fetchCurrenciesRequestError: action.payload.errorMessage,
        },
        allLoading: {
          ...state.allLoading,
          fetchCurrenciesRequestLoading: false,
        },
      };
    case FETCH_CARD_CREDENTIALS_FORM:
      return {
        ...state,
        cardCredentialsForm: {
          ...state.cardCredentialsForm,
        },
        allLoading: {
          ...state.allLoading,
          fetchCardCredentialsFormLoading: true,
        },
      };
    case CHANGE_CARD_CREDENTIALS_FORM_LOADING_STATUS:
      return {
        ...state,
        allLoading: {
          ...state.allLoading,
          fetchCardCredentialsFormLoading: action.payload.newStatus,
        },
      };
    case CHANGE_CURRENCY:
      return {
        ...state,
        cardCredentialsForm: {
          ...state.cardCredentialsForm,
          data: {
            ...state.cardCredentialsForm.data,
            currency: action.payload,
          },
        },
      };
    case SET_TRANSACTION_ID:
      return {
        ...state,
        transactionId: action.payload.transactionId,
      };
    case FETCH_CURRENT_TOKEN_VERSION_REQUEST:
      return {
        ...state,
        errors: {
          ...state.errors,
          fetchCurrentTokenVersionRequestError: null,
        },
        allLoading: {
          ...state.allLoading,
          fetchCurrentTokenVersionRequestLoading: true,
        },
      };
    case FETCH_CURRENT_TOKEN_VERSION_SUCCESS:
      return {
        ...state,
        currentTokenVersion: action.payload.data,
        errors: {
          ...state.errors,
          fetchCurrentTokenVersionRequestError: null,
        },
        allLoading: {
          ...state.allLoading,
          fetchCurrentTokenVersionRequestLoading: false,
        },
      };
    case FETCH_CURRENT_TOKEN_VERSION_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          fetchCurrentTokenVersionRequestError: action.payload.errorMessage,
        },
        allLoading: {
          ...state.allLoading,
          fetchCurrentTokenVersionRequestLoading: false,
        },
      };
    case CHANGE_IFRAME_CURRENCY_SELECT_STATUS:
      return {
        ...state,
        showIframeCurrencySelect: action.payload.newStatus,
      };
    case FETCH_POSSIBLE_PAYMENT_METHODS_REQUEST:
      return {
        ...state,
        errors: {
          ...state.errors,
          fetchPossiblePaymentMethodsError: null,
        },
        allLoading: {
          ...state.allLoading,
          fetchPossiblePaymentMethodsLoading: true,
        },
      };
    case FETCH_POSSIBLE_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        possiblePaymentMethods: action.payload.data,
        errors: {
          ...state.errors,
          fetchPossiblePaymentMethodsError: null,
        },
        allLoading: {
          ...state.allLoading,
          fetchPossiblePaymentMethodsLoading: false,
        },
      };
    case FETCH_POSSIBLE_PAYMENT_METHODS_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          fetchPossiblePaymentMethodsError: action.payload.errorMessage,
        },
        allLoading: {
          ...state.allLoading,
          fetchPossiblePaymentMethodsLoading: false,
        },
      };
    default:
      return state;
  }
};

export default paymentOptionReducer;
