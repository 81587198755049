import axios from 'axios';
import { appConfig } from '../appConfig';

const instance = axios.create({ baseURL: appConfig.REACT_APP_PAYMENT_SERVICE_API });

instance.interceptors.request.use((config) => {
  // add appId to request header for payment service
  if (config.url.includes(appConfig.REACT_APP_PAYMENT_SERVICE_API)) {
    config.headers['app_id'] = appConfig.REACT_APP_ID;
  }
  return config;
});

export default instance;
