import React from 'react';
import PropTypes from 'prop-types';

/**
 * Components
 */
import VoucherIsSelected from './VoucherIsSelected';
import VoucherIsNotSelected from './VoucherIsNotSelected';

/**
 * Styles
 */
import './styles.scss';

const Voucher = (props) => {
  const {
    isSelected,
    allowedToChangeEndDate,
    numberOfSelectOptions,
    label,
    selectOptionsForSelector,
    endDate,
    input,
  } = props;

  if (isSelected) {
    return (
      <VoucherIsSelected
        numberOfSelectOptions={numberOfSelectOptions}
        allowedToChangeEndDate={allowedToChangeEndDate}
        selectOptionsForSelector={selectOptionsForSelector}
        endDate={endDate}
        input={input}
        label={label}
      />
    );
  }
  return (
    <VoucherIsNotSelected
      label={label}
      endDate={endDate}
    />
  );
};

Voucher.propTypes = {
  allowedToChangeEndDate: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  input: PropTypes.instanceOf(Object).isRequired,
  numberOfSelectOptions: PropTypes.number.isRequired,
  selectOptionsForSelector: PropTypes.instanceOf(Array).isRequired,
  label: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

export default Voucher;
