import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from './types';

export const addNotification = ({ message, options }) => ({
  type: ADD_NOTIFICATION,
  payload: {
    message,
    options,
  },
});

export const removeNotification = (key) => ({
  type: REMOVE_NOTIFICATION,
  payload: {
    key,
  },
});
