import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import store from './store';

import ErrorFallback from './components/ErrorFallback';
import ErrorFallbackDummy from './components/ErrorFallback/Dummy';
import App from './App';

import './index.scss';

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={ErrorFallbackDummy}>
      <Provider store={store}>
        <ErrorFallback>
          <Router>
            <App />
          </Router>
        </ErrorFallback>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);
