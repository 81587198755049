import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { createStructuredSelector } from 'reselect';

import CustomButton from '../CustomButton';

import { thanksModalIsOpenedSelector } from './selectors';
import { loadingSelector } from '../LoadingSpinner/selectors';

import { closeThanksModal } from './actions';

import i18n from '../../i18n';

import useConditionalDelay from './hooks/useConditionalDelay';

import './styles.scss';

const modalWindowStyle = {
  padding: 0,
  overflow: 'hidden',
};

const ThanksModal = (props) => {
  const { modalWindowIsOpen, closeThanks, children, globalLoading, ...restProps } = props;

  const showModalAfterDelay = useConditionalDelay(!globalLoading, 500);

  return (
    <Modal
      {...restProps}
      dialogClassName="thanks-modal-window"
      show={modalWindowIsOpen && showModalAfterDelay}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="thanks-modal-window-container"
      style={modalWindowStyle}
    >
      <Modal.Body>
        {children}
        <CustomButton onClick={closeThanks}>{i18n.t('thanksALotButton')}</CustomButton>
      </Modal.Body>
    </Modal>
  );
};

ThanksModal.propTypes = {
  modalWindowIsOpen: PropTypes.bool.isRequired,
  globalLoading: PropTypes.bool.isRequired,
  closeThanks: PropTypes.func.isRequired,
  children: PropTypes.node,
};

ThanksModal.defaultProps = {
  children: null,
};

const mapStateToProps = createStructuredSelector({
  modalWindowIsOpen: thanksModalIsOpenedSelector,
  globalLoading: loadingSelector,
});

const mapDispatchToProps = (dispatch) => ({
  closeThanks: () => dispatch(closeThanksModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ThanksModal);
