import { ADD_PAYMENT_ERROR_MESSAGE, REMOVE_PAYMENT_ERROR_MESSAGE } from './types';

export const addPaymentErrorMessage = ({
  message,
  scrollToMessage = {
    enable: false,
    delay: 500,
  },
}) => ({
  type: ADD_PAYMENT_ERROR_MESSAGE,
  payload: {
    message,
    scrollToMessage,
  },
});

export const removePaymentErrorMessage = () => ({
  type: REMOVE_PAYMENT_ERROR_MESSAGE,
});
