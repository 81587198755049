/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup } from 'react-transition-group';
import { Collapse } from '@mui/material';

/**
 * Constants
 */
import { voucherPlan } from '../../../../constants';

/**
 * Components
 */
import Voucher from './Voucher';
import Common from './Common';

/**
 * Styles
 */
import './styles.scss';

const RadioInput = (props) => {
  const { disabled, input, selectOptions, type, selected } = props;

  const isSelected = selectOptions.some((option) => option._id === selected);

  const selectedOptions =
    selectOptions.find((option) => option._id === selected) || selectOptions[0];

  const {
    _id: id,
    description,
    subDescription,
    productType,
    endDate,
    allowedToChangeEndDate,
    label,
  } = selectedOptions;

  const uniqId = `radio_${input.name}_${id}`;

  /**
   * Defined class names, look at './styles.scss'
   */
  const classNameInterface = {
    helion_free: 'free',
    helion_basic: 'basic',
    helion_pro: 'pro',
  };

  /**
   * Options for selector
   */
  const selectOptionsForSelector = useMemo(
    () =>
      selectOptions.map((option) => ({
        value: option._id,
        label: option.label,
      })),
    [selectOptions],
  );

  /**
   * Number of select options
   */
  const numberOfSelectOptions = selectOptions?.length;

  /**
   * Is voucher
   */
  const isVoucher = type.split('|').includes(voucherPlan);

  return (
    <>
      <label
        htmlFor={uniqId}
        className="custom-radio"
      >
        <div className="d-flex flex-column w-100">
          <div className="d-flex flex-column mh-32 w-100">
            <div
              className={`d-flex ${
                isVoucher && !isSelected ? '' : 'flex-column'
              } flex-sm-row justify-content-between align-items-start ${
                isVoucher ? 'flex-voucher' : 'flex-no-voucher'
              } ${!isSelected && isVoucher ? 'voucher-is-not-selected' : 'voucher-is-selected'} ${
                isVoucher && allowedToChangeEndDate ? '' : 'end-user-voucher'
              }`}
            >
              <div className="d-flex flex-column">
                <div className="d-flex">
                  <div className="d-flex align-items-center radio-container">
                    <input
                      type="radio"
                      name={id}
                      id={uniqId}
                      onChange={input.onChange}
                      value={id}
                      checked={isSelected}
                      disabled={disabled}
                    />
                    <div className={`checkmark ${disabled ? 'disabled' : ''}`} />
                    <div
                      className={`label-container ${
                        isVoucher ? voucherPlan : classNameInterface?.[productType]
                      }`}
                    >
                      <span className="label">{`${
                        isVoucher ? voucherPlan : classNameInterface?.[productType]
                      }`}</span>
                    </div>
                  </div>
                  {!isVoucher && (
                    <div className="description-container">
                      <div className="description">{description}</div>
                      {subDescription && (
                        <TransitionGroup className="sub-description-container sub-description-container-sm">
                          <Collapse
                            mountOnEnter
                            unmountOnExit
                          >
                            <span
                              className={`sub-description ${classNameInterface?.[productType]}`}
                            >
                              {subDescription}
                            </span>
                          </Collapse>
                        </TransitionGroup>
                      )}
                    </div>
                  )}
                </div>

                {/* Sub description for devices with >= medium screen resolution */}
                {!isVoucher && subDescription && (
                  <TransitionGroup className="sub-description-container sub-description-container-md">
                    <Collapse
                      classes={{
                        wrapperInner: 'sub-description-wrapper-inner',
                      }}
                      mountOnEnter
                      unmountOnExit
                    >
                      <span className={`sub-description ${classNameInterface?.[productType]}`}>
                        {subDescription}
                      </span>
                    </Collapse>
                  </TransitionGroup>
                )}
              </div>

              <div className="price-container d-flex justify-content-end align-items-start">
                {isVoucher ? (
                  <Voucher
                    isSelected={isSelected}
                    allowedToChangeEndDate={allowedToChangeEndDate}
                    label={label}
                    selectOptionsForSelector={selectOptionsForSelector}
                    endDate={endDate}
                    numberOfSelectOptions={numberOfSelectOptions}
                    input={input}
                  />
                ) : (
                  <Common
                    isSelected={isSelected}
                    selectOptionsForSelector={selectOptionsForSelector}
                    label={label}
                    numberOfSelectOptions={numberOfSelectOptions}
                    input={input}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </label>
    </>
  );
};

RadioInput.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  selectOptions: PropTypes.instanceOf(Array).isRequired,
  disabled: PropTypes.bool,
  type: PropTypes.string.isRequired,
  selected: PropTypes.string.isRequired,
};

RadioInput.defaultProps = {
  disabled: false,
};

export default RadioInput;
