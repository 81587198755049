import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { ReactComponent as ExclamationTriangleIcon } from '../../assets/images/exclamation-triangle.svg';

import { paymentErrorSelector } from './selectors';

import { removePaymentErrorMessage } from './actions';

import i18n from '../../i18n';

import './styles.scss';

const PaymentError = (props) => {
  const {
    paymentError: {
      message,
      scrollToMessage: { enable, delay, timestamp },
    },
    removeMessage,
  } = props;

  useEffect(() => {
    if (message && enable && Number.isSafeInteger(delay) && timestamp > 0) {
      setTimeout(() => {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: 'smooth',
        });
      }, delay);
    }
  }, [message, enable, delay, timestamp]);

  if (!message) {
    return null;
  }

  return (
    <div
      className="payment-error"
      onClick={removeMessage}
    >
      <ExclamationTriangleIcon />
      <span className="payment-error-message">{i18n.t(message)}</span>
    </div>
  );
};

PaymentError.propTypes = {
  paymentError: PropTypes.shape({
    message: PropTypes.string,
    scrollToMessage: PropTypes.shape({
      enable: PropTypes.bool,
      delay: PropTypes.number,
      timestamp: PropTypes.number,
    }),
  }),
  removeMessage: PropTypes.func.isRequired,
};

PaymentError.defaultProps = {
  paymentError: {
    message: '',
    scrollToMessage: {
      enable: false,
      delay: 500,
      timestamp: 0,
    },
  },
};

const mapStateToProps = createStructuredSelector({
  paymentError: paymentErrorSelector,
});

const mapDispatchToProps = (dispatch) => ({
  removeMessage: () => dispatch(removePaymentErrorMessage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentError);
