export const appConfig = {
  REACT_APP_PAYMENT_SERVICE_API:
    process.env.REACT_APP_PAYMENT_SERVICE_API || 'https://payments-api.solarmanager.ch',
  REACT_APP_BACKEND_API: 'https://main-api.solar-manager.ch',
  REACT_APP_ID: process.env.REACT_APP_ID || 'helion',
  REACT_APP_END_USER_APP_ID: process.env.REACT_APP_END_USER_APP_ID || 'helionEndUserApp',
  REACT_APP_iMPRESSUM_URL:
    process.env.REACT_APP_iMPRESSUM_URL || 'https://www.helion.ch/de/impressum',
  REACT_APP_AGB_URL: process.env.REACT_APP_AGB_URL || 'https://www.helion.ch/de/agb',
  REACT_APP_MAIN_FRONT: 'https://one.helion.ch'
};
