import { OPEN_THANKS_MODAL, CLOSE_THANKS_MODAL } from './types';

const INITIAL_STATE = {
  opened: false,
};

const modalWindowReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OPEN_THANKS_MODAL:
      return {
        ...state,
        opened: true,
      };
    case CLOSE_THANKS_MODAL:
      return {
        ...state,
        opened: false,
      };
    default:
      return state;
  }
};

export default modalWindowReducer;
