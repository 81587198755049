import React from 'react';
import { Skeleton } from '@mui/material';

const PlansTableSkeleton = () => (
  <>
    <hr />
    <div className="plans">
      <table className="plans-table">
        <thead className="thead">
          <tr>
            <td className="description">
              <Skeleton
                variant="rectangular"
                width="100%"
                height={30}
              />
            </td>
            {[...Array(3).keys()].map((cell) => (
              <td key={cell}>
                <Skeleton
                  variant="rectangular"
                  width={40}
                  height={30}
                />
              </td>
            ))}
          </tr>
        </thead>
        <tbody className="tbody">
          {[...Array(10).keys()].map((row) => (
            <tr key={row}>
              <td className="description">
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={36}
                />
              </td>
              {[...Array(3).keys()].map((value) => (
                <td key={value}>
                  <Skeleton
                    variant="rectangular"
                    width={40}
                    height={36}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <table
        className="tfoot"
        cellSpacing="1"
      >
        <tbody>
          <tr>
            <td className="description">
              <Skeleton
                variant="rectangular"
                width="100%"
                height={25}
              />
            </td>
            {[...Array(3).keys()].map((value) => (
              <td key={value}>
                <Skeleton
                  variant="rectangular"
                  width={40}
                  height={25}
                />
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  </>
);

export default PlansTableSkeleton;
