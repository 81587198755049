import React from 'react';

import MyPlan from '../MyPlan';
import PlansTable from '../PlansTable';

import './styles.scss';

const Plan = () => (
  <div className="webview-component plan">
    <MyPlan />
    <PlansTable />
  </div>
);

export default Plan;
