import {
  SET_USER_JWT_TOKEN,
  SET_END_USER_ID,
  FETCH_ME_REQUEST,
  FETCH_ME_SUCCESS,
  FETCH_ME_FAILURE,
  SET_APP_TYPE,
  FETCH_IS_SUBSCRIPTION_PRODUCT_CHANGE_ALLOWED_REQUEST,
  FETCH_IS_SUBSCRIPTION_PRODUCT_CHANGE_ALLOWED_SUCCESS,
  FETCH_IS_SUBSCRIPTION_PRODUCT_CHANGE_ALLOWED_FAILURE,
  FETCH_TRANSACTION_ERROR,
} from './types';

export const setUserJwtToken = (jwtToken) => ({
  type: SET_USER_JWT_TOKEN,
  payload: {
    jwtToken,
  },
});

export const setEndUserId = (endUserId) => ({
  type: SET_END_USER_ID,
  payload: {
    endUserId,
  },
});

export const fetchMeRequest = () => ({
  type: FETCH_ME_REQUEST,
});

export const fetchMeSuccess = (data) => ({
  type: FETCH_ME_SUCCESS,
  payload: {
    data,
  },
});

export const fetchMeFailure = (errorMessage) => ({
  type: FETCH_ME_FAILURE,
  payload: {
    errorMessage,
  },
});

export const setAppType = (appType) => ({
  type: SET_APP_TYPE,
  payload: {
    appType,
  },
});

export const fetchIsSubscriptionProductChangeAllowedRequest = (endUserId) => ({
  type: FETCH_IS_SUBSCRIPTION_PRODUCT_CHANGE_ALLOWED_REQUEST,
  payload: {
    endUserId,
  },
});

export const fetchIsSubscriptionProductChangeAllowedSuccess = (allowed) => ({
  type: FETCH_IS_SUBSCRIPTION_PRODUCT_CHANGE_ALLOWED_SUCCESS,
  payload: {
    allowed,
  },
});

export const fetchIsSubscriptionProductChangeAllowedFailure = (errorMessage) => ({
  type: FETCH_IS_SUBSCRIPTION_PRODUCT_CHANGE_ALLOWED_FAILURE,
  payload: {
    errorMessage,
  },
});

export const fetchTransactionErrorRequest = (transactionId) => ({
  type: FETCH_TRANSACTION_ERROR,
  payload: {
    transactionId,
  },
});
