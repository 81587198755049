import {
  CLOSE_CHANGE_PLAN_WINDOW,
  TOGGLE_CHANGE_PLAN_WINDOW,
  FETCH_CURRENT_SUBSCRIPTION_REQUEST,
  FETCH_CURRENT_SUBSCRIPTION_SUCCESS,
  FETCH_CURRENT_SUBSCRIPTION_FAILURE,
  CHANGE_PLAN_REQUEST,
  CHANGE_PLAN_SUCCESS,
  CHANGE_PLAN_FAILURE,
  FETCH_IFRAME_URL_TOKEN_REQUEST,
  FETCH_IFRAME_URL_TOKEN_SUCCESS,
  FETCH_IFRAME_URL_TOKEN_FAILURE,
  CHANGE_IFRAME_USER_TOKEN_LOADING_STATUS,
  FETCH_NEXT_PAYMENT_DATE_REQUEST,
  FETCH_NEXT_PAYMENT_DATE_SUCCESS,
  FETCH_NEXT_PAYMENT_DATE_FAILURE,
  FETCH_END_DATE_REQUEST,
  FETCH_END_DATE_FAILURE,
  FETCH_END_DATE_SUCCESS,
} from './types';
import { isHelionBasicTrial, isHelionProTrial } from './utils';

const INITIAL_STATE = {
  changePlanWindow: {
    active: false,
  },
  currentPlan: {
    product: null,
    fee: [],
    type: '',
  },
  newPlanId: null,
  changePlanData: {
    javascriptUrl: '',
    action: '',
  },
  nextPaymentDate: '',
  errors: {
    fetchCurrentSubscriptionRequestError: null,
    changePlanRequestError: null,
    fetchIframeUrlTokenRequestError: null,
  },
  allLoading: {
    fetchCurrentSubscriptionRequestLoading: false,
    changePlanRequestLoading: false,
    fetchIframeUrlTokenRequestLoading: false,
    iframeIsLoading: false,
    fetchNextPaymentDateRequestLoading: false,
  },
};

const myPlanReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CLOSE_CHANGE_PLAN_WINDOW:
      return {
        ...state,
        changePlanWindow: {
          ...state.changePlanWindow,
          active: false,
        },
      };
    case TOGGLE_CHANGE_PLAN_WINDOW:
      return {
        ...state,
        changePlanWindow: {
          ...state.changePlanWindow,
          active: !state.changePlanWindow.active,
        },
      };
    case FETCH_CURRENT_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        errors: {
          ...state.errors,
          fetchCurrentSubscriptionRequestError: null,
        },
        allLoading: {
          ...state.allLoading,
          fetchCurrentSubscriptionRequestLoading: true,
        },
      };
    // TODO
    case FETCH_CURRENT_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        currentPlan: {
          ...state.currentPlan,
          product:
            isHelionProTrial(action.payload) || isHelionBasicTrial(action.payload)
              ? {
                  ...action.payload.product,
                  billingCycle: 'MONTH',
                }
              : action.payload.product,
          fee: action.payload.fee,
          endDate: action.payload.endDate,
          type: action.payload.type,
          _id: action.payload._id,
        },
        newPlanId: action.payload._id,
        errors: {
          ...state.errors,
          fetchCurrentSubscriptionRequestError: null,
        },
        allLoading: {
          ...state.allLoading,
          fetchCurrentSubscriptionRequestLoading: false,
        },
      };
    case FETCH_END_DATE_REQUEST:
      return {
        ...state,
        errors: {
          ...state.errors,
          endDateError: null,
        },
        allLoading: {
          ...state.allLoading,
          endDateLoading: true,
        },
      };
    case FETCH_END_DATE_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          endDateError: action.payload.errorMessage,
        },
        allLoading: {
          ...state.allLoading,
          endDateLoading: false,
        },
      };
    case FETCH_END_DATE_SUCCESS:
      return {
        ...state,
        currentPlan: {
          ...state.currentPlan,
          endDate: action.payload.endDate,
        },
        errors: {
          ...state.errors,
          endDateError: null,
        },
        allLoading: {
          ...state.allLoading,
          endDateLoading: false,
        },
      };
    case FETCH_CURRENT_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          fetchCurrentSubscriptionRequestError: action.payload.errorMessage,
        },
        allLoading: {
          ...state.allLoading,
          fetchCurrentSubscriptionRequestLoading: false,
        },
      };
    case CHANGE_PLAN_REQUEST:
      return {
        ...state,
        newPlanId: action.payload.productVersionId,
        errors: {
          ...state.errors,
          changePlanRequestError: null,
        },
        allLoading: {
          ...state.allLoading,
          changePlanRequestLoading: true,
        },
      };
    case CHANGE_PLAN_SUCCESS:
      return {
        ...state,
        changePlanData: {
          ...state.changePlanData,
          javascriptUrl: action.payload.javascriptUrl,
          action: 'changeSubscriptionProductId',
        },
        errors: {
          ...state.errors,
          changePlanRequestError: null,
        },
        allLoading: {
          ...state.allLoading,
          changePlanRequestLoading: false,
        },
      };
    case CHANGE_PLAN_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          changePlanRequestError: action.payload.errorMessage,
        },
        allLoading: {
          ...state.allLoading,
          changePlanRequestLoading: false,
        },
      };
    case FETCH_IFRAME_URL_TOKEN_REQUEST:
      return {
        ...state,
        errors: {
          ...state.errors,
          fetchIframeUrlTokenRequestError: null,
        },
        allLoading: {
          ...state.allLoading,
          fetchIframeUrlTokenRequestLoading: true,
          iframeIsLoading: true,
        },
      };
    case FETCH_IFRAME_URL_TOKEN_SUCCESS:
      return {
        ...state,
        changePlanData: {
          ...state.changePlanData,
          javascriptUrl: action.payload.javascriptUrl,
          action: 'addOrUpdateUserToken',
        },
        errors: {
          ...state.errors,
          fetchIframeUrlTokenRequestError: null,
        },
        allLoading: {
          ...state.allLoading,
          fetchIframeUrlTokenRequestLoading: false,
        },
      };
    case FETCH_IFRAME_URL_TOKEN_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          fetchIframeUrlTokenRequestError: action.payload.errorMessage,
        },
        allLoading: {
          ...state.allLoading,
          fetchIframeUrlTokenRequestLoading: false,
          iframeIsLoading: false,
        },
      };
    case CHANGE_IFRAME_USER_TOKEN_LOADING_STATUS:
      return {
        ...state,
        allLoading: {
          ...state.allLoading,
          iframeIsLoading: action.payload.newStatus,
        },
      };
    case FETCH_NEXT_PAYMENT_DATE_REQUEST:
      return {
        ...state,
        allLoading: {
          ...state.allLoading,
          fetchNextPaymentDateRequestLoading: true,
        },
      };
    case FETCH_NEXT_PAYMENT_DATE_SUCCESS:
      return {
        ...state,
        nextPaymentDate: action.payload.nextPaymentDate,
        allLoading: {
          ...state.allLoading,
          fetchNextPaymentDateRequestLoading: false,
        },
      };
    case FETCH_NEXT_PAYMENT_DATE_FAILURE:
      return {
        ...state,
        nextPaymentDate: '',
        allLoading: {
          ...state.allLoading,
          fetchNextPaymentDateRequestLoading: false,
        },
      };
    default:
      return state;
  }
};

export default myPlanReducer;
