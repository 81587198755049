import React from 'react';

import { appConfig } from '../../appConfig';

import i18n from '../../i18n';

import './styles.scss';

const Footer = () => (
  <div className="footer-container">
    <span>
      <a
        href={appConfig.REACT_APP_iMPRESSUM_URL}
        target="_blank"
        rel="noreferrer"
      >
        {i18n.t('impressum')}
      </a>
      &nbsp;
      {i18n.t('and')}
      &nbsp;
      <a
        href={appConfig.REACT_APP_AGB_URL}
        target="_blank"
        rel="noreferrer"
      >
        {i18n.t('termsAndConditions')}
      </a>
    </span>
  </div>
);

export default Footer;
