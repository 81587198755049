import { all, call, takeLatest, put, select } from '@redux-saga/core/effects';
import axios from '../../api';
import { v4 as uuidv4 } from 'uuid';

import i18n from '../../i18n';

import {
  SUBSCRIPTIONS_USER_CREATE_API,
  SUBSCRIPTIONS_MY_API,
  SUBSCRIPTIONS_CURRENT_API,
  MY_CURRENT_SUBSCRIPTION_NEXT_PAYMENT_DATE_API,
  USER_CURRENT_SUBSCRIPTION_NEXT_PAYMENT_DATE_API,
  MY_NEXT_SUBSCRIPTION_API,
  USER_NEXT_SUBSCRIPTION_API,
  SUBSCRIPTIONS_CURRENT_USER_API,
} from '../../api/endpoints';

import {
  FETCH_CURRENT_SUBSCRIPTION_REQUEST,
  CHANGE_PLAN_REQUEST,
  FETCH_END_DATE_REQUEST,
} from './types';

import {
  fetchCurrentSubscriptionSuccess,
  fetchCurrentSubscriptionFailure,
  changePlanSuccess,
  changePlanFailure,
  closeChangePlanWindow,
  fetchNextPaymentDateSuccess,
  fetchNextPaymentDateFailure,
  fetchIframeUrlTokenRequest,
  changeEndDateRequest,
  changeEndDateRequestFailure,
  changeEndDateRequestSuccess,
} from './actions';
import { openThanksModal } from '../ThanksModal/actions';
import { changeCardCredentialsFormLoadingStatus, setTransactionId } from '../PaymentOption/actions';
import { addNotification } from '../Notifications/actions';
import { changeIframeMode } from '../HomePage/actions';
import { fetchPlansRequest } from '../PlansTable/actions';
import { addPaymentErrorMessage } from '../PaymentError/actions';

import { currentTokenVersionSelector } from '../PaymentOption/selectors';
import {
  getAppType,
  getEndUserId,
  getJwtToken,
  getMyRoleType,
} from '../AuthorizationApp/selectors';
import { getCurrentPlan, getCurrentPlanEndDate } from './selectors';

import { fetchPossiblePaymentMethodsRequest } from '../PaymentOption/sagas';

import { accessRole, END_USER } from '../../constants';

export function* fetchNextPaymentDate() {
  try {
    const myRoleType = yield select(getMyRoleType);
    let response = {};
    if (accessRole.includes(myRoleType)) {
      const endUserId = yield select(getEndUserId);
      const params = { endUserId };
      response = yield call(() =>
        axios.get(USER_CURRENT_SUBSCRIPTION_NEXT_PAYMENT_DATE_API, { params }),
      );
    } else if (myRoleType === END_USER) {
      response = yield call(() => axios.get(MY_CURRENT_SUBSCRIPTION_NEXT_PAYMENT_DATE_API));
    } else {
      throw new Error(i18n.t('roleAccessIsDenied'));
    }
    const { data } = response;
    const { nextPaymentDate = null } = data;
    yield put(fetchNextPaymentDateSuccess(nextPaymentDate));
  } catch (error) {
    yield put(fetchNextPaymentDateFailure(error.message));
  }
}

export function* fetchCurrentSubscription() {
  try {
    const myRoleType = yield select(getMyRoleType);
    let response = {};
    if (accessRole.includes(myRoleType)) {
      const endUserId = yield select(getEndUserId);
      response = yield call(() => axios.get(`${SUBSCRIPTIONS_CURRENT_API}/${endUserId}`));
    } else if (myRoleType === END_USER) {
      response = yield call(() => axios.get(SUBSCRIPTIONS_MY_API));
    } else {
      throw new Error(i18n.t('roleAccessIsDenied'));
    }
    const { data } = response;
    const { currentProductVersion = {}, endDate } = data;
    const { product, fee, type, _id } = currentProductVersion;
    if (product) {
      yield put(
        fetchCurrentSubscriptionSuccess({
          product,
          fee,
          type,
          _id,
          endDate,
        }),
      );
    } else {
      yield put(
        fetchCurrentSubscriptionFailure(`${i18n.t('oops')} ${i18n.t('somethingWentWrong')}`),
      );
    }
    yield call(fetchNextPaymentDate);
  } catch (error) {
    yield put(fetchCurrentSubscriptionFailure(error.message));
  }
}

export function* fetchNextSubscription() {
  try {
    const myRoleType = yield select(getMyRoleType);
    let response = {};
    if (accessRole.includes(myRoleType)) {
      const endUserId = yield select(getEndUserId);
      response = yield call(() => axios.get(`${USER_NEXT_SUBSCRIPTION_API}/${endUserId}`));
    } else if (myRoleType === END_USER) {
      response = yield call(() => axios.get(MY_NEXT_SUBSCRIPTION_API));
    } else {
      throw new Error(i18n.t('roleAccessIsDenied'));
    }
    const { data } = response;
    const { currentProductVersion = {} } = data;
    const { product, fee, type, _id } = currentProductVersion;
    if (product) {
      yield put(
        fetchCurrentSubscriptionSuccess({
          product,
          fee,
          type,
          _id,
        }),
      );
    } else {
      yield put(
        fetchCurrentSubscriptionFailure(`${i18n.t('oops')} ${i18n.t('somethingWentWrong')}`),
      );
    }
    yield put(fetchNextPaymentDateSuccess(null)); // do not show next payment date
  } catch (error) {
    yield call(fetchCurrentSubscription);
  }
}

export function* fetchCurrentSubscriptionSaga() {
  try {
    yield call(fetchNextSubscription);
  } catch (error) {
    yield put(fetchCurrentSubscriptionFailure(error.message));
  }
}

export function* runChangePlanSuccess() {
  try {
    yield put(closeChangePlanWindow()); // collapse change plan window
    yield put(changeIframeMode(0)); // close iframe
    yield put(openThanksModal()); // open modal window with "thanks" message
  } catch (error) {
    if (error?.response?.data?.statusCode === 409) {
      yield put(changePlanFailure(null)); // error
      yield put(
        addNotification({
          message: error?.response?.data?.message,
          options: {
            key: uuidv4(),
            autoHideDuration: 2600,
            variant: 'warning',
          },
        }),
      );
    } else if (error?.response?.data?.statusCode) {
      yield put(changePlanFailure(error?.response?.data?.message)); // error
    } else {
      yield put(changePlanFailure(error?.message)); // error
    }
  }
}

export function* runChangePlanRequest(action) {
  try {
    const myRoleType = yield select(getMyRoleType);
    const currentPlanEndDate = yield select(getCurrentPlanEndDate);
    const { _id: currentProductVersionId } = yield select(getCurrentPlan);
    const appType = yield select(getAppType);
    const endUserId = yield select(getEndUserId);
    const jwtToken = yield select(getJwtToken);
    const { endDate, productVersionId } = action?.payload || {};
    if (productVersionId === currentProductVersionId) {
      // TODO: handle case when endDate did not changed
      if (endDate !== currentPlanEndDate) {
        yield put(changeEndDateRequest(endDate));
        yield put(changePlanSuccess(null));
        yield call(fetchNextSubscription);
        yield runChangePlanSuccess();
      }
    } else {
      const bareJwtToken = jwtToken.split(/\s+/).pop();
      let response = {};
      if (accessRole.includes(myRoleType)) {
        const successUrl = new URL(
          `${window.location.origin}/${bareJwtToken}/${endUserId}?result=success`,
        );
        const failedUrl = new URL(
          `${window.location.origin}/${bareJwtToken}/${endUserId}?result=failed`,
        );
        if (appType) {
          successUrl.searchParams.set('app', appType);
          failedUrl.searchParams.set('app', appType);
        }

        const redirectUrl = new URLSearchParams(location.search)?.get('redirectUrl');

        if (redirectUrl) {
          successUrl.searchParams.set('redirectUrl', redirectUrl);
          failedUrl.searchParams.set('redirectUrl', redirectUrl);
        }

        response = yield call(() =>
          axios.post(
            `${SUBSCRIPTIONS_USER_CREATE_API}/${endUserId}/${action.payload.productVersionId}`,
            {
              successUrl,
              failedUrl,
              endDate: action?.payload?.endDate,
            },
          ),
        );
      } else if (myRoleType === END_USER) {
        const successUrl = new URL(`${window.location.origin}/${bareJwtToken}?result=success`);
        const failedUrl = new URL(`${window.location.origin}/${bareJwtToken}?result=failed`);
        if (appType) {
          successUrl.searchParams.set('app', appType);
          failedUrl.searchParams.set('app', appType);
        }
        response = yield call(() =>
          axios.post(
            `${SUBSCRIPTIONS_USER_CREATE_API}/${endUserId}/${action.payload.productVersionId}`,
            {
              successUrl,
              failedUrl,
            },
          ),
        );
      } else {
        throw new Error(i18n.t('roleAccessIsDenied'));
      }
      const { data } = response;
      const { javascriptUrl } = data;
      if (javascriptUrl === null) {
        yield put(changePlanSuccess(javascriptUrl)); // save { javascriptUrl } in store
        yield call(fetchNextSubscription);
        yield runChangePlanSuccess();
      }
      if (typeof javascriptUrl === 'string') {
        const transactionId = new URL(javascriptUrl).searchParams.get('transactionId');
        yield fetchPossiblePaymentMethodsRequest(transactionId);
        yield put(changePlanSuccess(javascriptUrl)); // save { javascriptUrl } in store
        yield put(setTransactionId(transactionId));
        const currentTokenVersion = yield select(currentTokenVersionSelector);
        const currentTokenVersionState = currentTokenVersion?.state;
        if (currentTokenVersionState !== 'ACTIVE') {
          if (myRoleType === END_USER) {
            yield put(fetchIframeUrlTokenRequest());
          }
          yield put(
            addPaymentErrorMessage({
              message: i18n.t('youHaveToAddCard'),
              scrollToMessage: {
                enable: myRoleType !== END_USER,
                delay: 500,
              },
            }),
          );
        }
        if (currentTokenVersionState === 'ACTIVE') {
          yield put(changeCardCredentialsFormLoadingStatus(true));
          yield put(changeIframeMode(2));
        }
      }
      // Update plans table, change plan permissions
      yield put(fetchPlansRequest(endUserId));
    }
  } catch (error) {
    if (error?.response?.data?.statusCode === 409) {
      yield put(changePlanFailure(null)); // error
      if (
        [
          'Please add means of payment',
          'Bitte Zahlungsmittel hinzufügen',
          'Veuillez ajouter le moyen de paiement',
          'Si prega di aggiungere i mezzi di pagamento',
        ].includes(error?.response?.data?.message)
      ) {
        const myRoleType = yield select(getMyRoleType);
        if (myRoleType === END_USER) {
          yield put(fetchIframeUrlTokenRequest({}));
        }
        yield put(
          addPaymentErrorMessage({
            message: error?.response?.data?.message,
            scrollToMessage: {
              enable: myRoleType !== END_USER,
              delay: 500,
            },
          }),
        );
      } else {
        yield put(
          addNotification({
            message: error?.response?.data?.message,
            options: {
              key: uuidv4(),
              autoHideDuration: 2600,
              variant: 'warning',
            },
          }),
        );
      }
    } else if (error?.response?.data?.statusCode) {
      yield put(changePlanFailure(error?.response?.data?.message)); // error
    } else {
      yield put(changePlanFailure(error?.message)); // error
    }
  }
}

export function* runChangeEndDate(action) {
  try {
    const endUserId = yield select(getEndUserId);
    const {
      data: { endDate },
    } = yield call(() =>
      axios.put(`${SUBSCRIPTIONS_CURRENT_USER_API}/${endUserId}/end-date`, {
        endDate: action?.payload?.endDate,
      }),
    );
    if (endDate) {
      yield put(changeEndDateRequestSuccess(endDate));
    } else {
      yield put(changeEndDateRequestFailure(i18n.t('endDateIsMissed')));
    }
  } catch (error) {
    yield put(changeEndDateRequestFailure(error?.message)); // error
  }
}

export function* onfetchCurrentSubscription() {
  yield takeLatest(FETCH_CURRENT_SUBSCRIPTION_REQUEST, fetchCurrentSubscriptionSaga);
}

export function* onChangePlanRequest() {
  yield takeLatest(CHANGE_PLAN_REQUEST, runChangePlanRequest);
}

export function* onChangeEndDate() {
  yield takeLatest(FETCH_END_DATE_REQUEST, runChangeEndDate);
}

export default function* myPlanSaga() {
  yield all([call(onfetchCurrentSubscription), call(onChangePlanRequest), call(onChangeEndDate)]);
}
