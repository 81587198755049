import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { upperCase } from 'lodash';
import moment from 'moment';

/**
 * Components
 */
import DatePicker from '../../../DatePicker';
import CustomSelect from '../../../CustomSelect';

/**
 * Validation
 */
import { date, lessThanToday, required } from '../../../../utils/validation';

/**
 * Styles
 */
import './styles.scss';

/**
 * Internationalization
 */
import i18n from '../../../../i18n';

const VoucherIsSelected = (props) => {
  const {
    allowedToChangeEndDate,
    label,
    selectOptionsForSelector,
    numberOfSelectOptions,
    endDate,
    input,
  } = props;

  return (
    <div className="d-flex flex-column w-100 voucher-is-selected">
      {allowedToChangeEndDate ? (
        numberOfSelectOptions > 1 ? (
          <>
            <CustomSelect
              options={selectOptionsForSelector}
              onChange={input?.onChange}
              value={input?.value}
              className="billing-cycle-select"
            />
            <div className="d-flex justify-content-between justify-content-sm-end">
              <span className="voucher-is-select-date-label">{i18n.t('endDate')}:</span>
              <Field
                name="endDate"
                component={DatePicker}
                validate={[required, date, lessThanToday]}
              />
            </div>
          </>
        ) : (
          <div className="plan-label d-flex justify-content-end">
            {i18n.t('plan')}:<span>{upperCase(label)}</span>
          </div>
        )
      ) : (
        <div className="d-flex flex-column align-items-end w-100">
          <div className="plan-label d-flex justify-content-end">
            {i18n.t('plan')}:
            <span>{endDate && label ? upperCase(label) : i18n.t('notSelected')}</span>
          </div>
          <div className="end-on-label">
            {i18n.t('endOn')}:
            <span>{endDate ? moment(endDate).format('DD/MM/yy') : i18n.t('noDate')}</span>
          </div>
        </div>
      )}
    </div>
  );
};

VoucherIsSelected.propTypes = {
  allowedToChangeEndDate: PropTypes.bool.isRequired,
  input: PropTypes.instanceOf(Object).isRequired,
  selectOptionsForSelector: PropTypes.instanceOf(Array).isRequired,
  numberOfSelectOptions: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

export default VoucherIsSelected;
