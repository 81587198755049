import { all, call, takeLatest, put, select } from '@redux-saga/core/effects';
import axios from '../../api';

import {
  USER_ME_API,
  IS_SUBSCRIPTION_PRODUCT_CHANGE_ALLOWED_API,
  TRANSACTION_ERROR_API,
} from '../../api/endpoints';

import { FETCH_ME_REQUEST, FETCH_TRANSACTION_ERROR } from './types';
import { FETCH_USER_SUCCESS } from '../HomePage/types';
import { addNotification } from '../Notifications/actions';

import {
  fetchMeSuccess,
  fetchMeFailure,
  setEndUserId,
  fetchIsSubscriptionProductChangeAllowedFailure,
  fetchIsSubscriptionProductChangeAllowedSuccess,
} from './actions';
import { fetchUserRequest, fetchUserSuccess } from '../HomePage/actions';

import { getEndUserId } from './selectors';

import { setLanguage } from '../../i18n/utils';
import { appConfig } from '../../appConfig';

import i18n from '../../i18n';

import {
  END_USER,
  PV_INSTALLER,
  PV_INSTALLER_EMPLOYEE,
  PV_INSTALLER_EMPLOYEE_READ_ONLY,
  ROOT,
  SOLAR_ADMIN,
  SUPPORT,
} from '../../constants';

export function* fetchMe() {
  try {
    const { data } = yield call(() =>
      axios.get(USER_ME_API, {
        params: {
          app: appConfig.REACT_APP_END_USER_APP_ID,
          isWeb: true,
        },
      }),
    );
    const {
      role: { type: myRoleType },
    } = data || {};
    if (myRoleType === END_USER) {
      const { _id, language } = data || {};
      setLanguage(language);
      yield put(fetchMeSuccess(data));
      yield put(fetchUserSuccess(data));
      yield put(setEndUserId(_id));
      // TODO: check what roles do have permission to see the payments page
    } else if (
      [
        ROOT,
        SOLAR_ADMIN,
        SUPPORT,
        PV_INSTALLER,
        PV_INSTALLER_EMPLOYEE,
        PV_INSTALLER_EMPLOYEE_READ_ONLY,
      ].includes(myRoleType)
    ) {
      yield put(fetchMeSuccess(data));
      const endUserId = yield select(getEndUserId);
      yield put(fetchUserRequest(endUserId));
    } else {
      yield put(fetchMeFailure(i18n.t('roleAccessIsDenied')));
    }
  } catch (error) {
    yield put(fetchMeFailure(error.message));
  }
}

export function* isSubscriptionChangeAllowed(action) {
  try {
    const {
      payload: {
        data: { _id },
      },
    } = action;
    const { data } = yield call(() =>
      axios.get(`${IS_SUBSCRIPTION_PRODUCT_CHANGE_ALLOWED_API}/${_id}`),
    );
    const { isSubscriptionProductChangeAllowed: allowed } = data || {};
    yield put(fetchIsSubscriptionProductChangeAllowedSuccess(allowed));
  } catch (error) {
    yield put(fetchIsSubscriptionProductChangeAllowedFailure(error?.message));
  }
}

export function* fetchTransactionError({ payload }) {
  const key = crypto.randomUUID();

  try {
    const { data } = yield call(() =>
      axios.get(`${TRANSACTION_ERROR_API}/${payload.transactionId}`),
    );

    yield put(
      addNotification({
        message: data.description || i18n.t('error'),
        options: {
          key,
          autoHideDuration: 3600,
          variant: 'warning',
        },
      }),
    );
  } catch (error) {
    yield put(
      addNotification({
        message: i18n.t('error'),
        options: {
          key,
          autoHideDuration: 2600,
          variant: 'warning',
        },
      }),
    );
  }
}

export function* onFetchMe() {
  yield takeLatest(FETCH_ME_REQUEST, fetchMe);
}

export function* onSetEndUserId() {
  yield takeLatest(FETCH_USER_SUCCESS, isSubscriptionChangeAllowed);
}

export function* onFetchTransactionError() {
  yield takeLatest(FETCH_TRANSACTION_ERROR, fetchTransactionError);
}

export default function* authorizationSaga() {
  yield all([call(onFetchMe), call(onSetEndUserId), call(onFetchTransactionError)]);
}
