import AuthorizationApp from './components/AuthorizationApp';
import HomePage from './components/HomePage';
import ErrorFallback from './components/ErrorFallback';

const routes = [
  {
    path: '/payment-credentials-form/:jwt',
    component: AuthorizationApp(HomePage),
    key: '#openCreditCardForm',
  },
  {
    path: '/:jwt',
    exact: true,
    component: AuthorizationApp(HomePage),
    key: '#homePage',
  },
  {
    path: '/:jwt/:endUserId',
    exact: true,
    component: AuthorizationApp(HomePage),
    key: '#homePageEndUser',
  },
  {
    component: ErrorFallback,
    key: '#notFoundPage',
  },
];

export default routes;
